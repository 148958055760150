import React, { useRef, useState } from "react";
import Nav from "../components/Navbar.tsx"
import WidgetBot from '@widgetbot/react-embed'
import "../styles/globals.css";
import data from "../data/courses.js";
import TradingCoursePricing from "../components/TradingCoursePricing.tsx";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
function Recap() {
    const { showView } = useContext(ViewContext);



    return (
        <div style={{ background: "linear-gradient(112deg, rgba(0, 0, 0, 0.00) 0%, #363636 105.71%)" }} className="flex flex-col text-white px-1 pb-10">

            <Nav />


            <div className="mt-[10rem]"></div>
            <div className="h-screen flex items-center justify-center gap-10 flex-col">
            <div className="text-white scroll-my-28 max-md:scroll-my-20 text-center text-3xl font-medium max-md:text-2xl">
                Full transparency. See all of our trades below.
            </div>
            <div className=' flex justify-center'>
                <a className="butt text-black cursor-pointer mx-auto text-center text-lg font-medium w-[257px] bg-gray-200 self-stretch justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5" href="https://whop.com/unity-academy/">
                    Get Access
                </a>
            </div>
            <WidgetBot className="w-[70%] h-[100%] max-md:w-[98%]"
                server="1087843967573438504"
                channel="1087883581822615563"
            />
            </div>
        </div>
    );
}

export default Recap;