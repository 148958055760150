import * as React from "react";
import Nav from "../components/Navbar.tsx"
import Footer from "../components/Footer.tsx"
import "./affiliate.css"
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";

import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.tsx";

function Legal() {
    const { showView } = useContext(ViewContext);
    return (
        <>
            <div style={{ background: "linear-gradient(112deg, rgba(0, 0, 0, 0.00) 0%, #363636 105.71%)" }} className="flex flex-col text-white px-1 pb-10">

                <Nav />



                <div className="mt-[10rem] w-full flex items-center justify-center gap-10 flex-col">
                    <motion.div {...slideUp(showView)} className="text-white scroll-my-28 max-md:scroll-my-20 text-center text-3xl font-medium max-md:text-2xl">
                    Here's the legal stuff, Find Our Policies and Disclaimer below.

                    </motion.div>
                    <motion.div {...slideUp(showView)} className="text-white scroll-my-28 max-md:scroll-my-20 text-center text-2xl font-light max-md:text-xl">
                        (our lawyers said its required)
                    </motion.div>
                    <div className="phrase mt-10 w-[80%] flex flex-col items-center justify-center">
                        <motion.div {...slideUp(showView)} className="p1 w-full opacity-80 text-start text-2xl font-bold">
                            Disclaimer & Server Rules
                        </motion.div>

                        <motion.div {...slideUp(showView)} className="mt-4 text-[#858585] font-medium">
                            Unity Academy Trading is an educational service. As such, crypto and trade recommendations in both our Discord server and other platforms are solely our own. Unity Academy does not indicate that any subscriber will achieve profits or losses similar to those discussed on this website. The past performance of any trading system or methodology is not indicative of future results. Unity Academy does not guarantee any claims or recommendations and is not responsible for any losses incurred. Securities and options trading are highly speculative investments and involve substantial risk. If you do not fully understand these risks, seek independent advice from a licensed financial advisor.

                            By joining the Unity Academy Discord server, you are agreeing to the following conditions. Any violation of these will result in immediate cancellation of your membership and ban from all media outlets. We are dedicated to maintaining the integrity and quality of the service and will enforce the following:
                            <br />
                            <br />
                            1) Be respectful and mindful of other members. If you are consistently offensive, you will receive a warning. If it persists your membership will be terminated.
                            <br />
                            2) Do not spam or troll.
                            <br />
                            3) No self-promoting or advertising of products/services of any kind without prior administrative permission.
                            <br />
                            4) NSFW comments and images are strictly prohibited.
                            <br />
                            5) No solicitations for funding or account management.
                            <br />
                            <br />
                            Any redistribution of our content is strictly prohibited and is considered a violation of our intellectual property and will result in copyright infringement charges and an instantaneous ban from our services.

                            Your subscription is for you and you only.
                            <br /> If we come to the conclusion that you are reproducing our content or signals, you will be permanently banned from our services without a refund. Please note that everything in our Discord server is protected under intellectual property law, if you are reproducing our content or signals for your own financial gain we will pursue all available damages and remedies through legal action, including but not limited to all subscription funds you have received.

                            Members are fully responsible for their own trading activity.
                            By subscribing to our service, you accept that you have read and understand this Disclaimer.
                        </motion.div>
                    </div>
                    <div className="phrase mt-10 w-[80%] flex flex-col items-center justify-center">
                        <motion.div {...slideUp(showView)} className="p1 w-full opacity-80 text-start text-2xl font-bold">
                            Privacy Policy
                        </motion.div>

                        <motion.div {...slideUp(showView)} className="mt-4 text-[#858585] font-medium">
                            Information that is gathered from visitors In common with other websites, log files are stored on the web server saving details such as the visitor’s IP address, browser type, referring page and time of visit. Cookies may be used to remember visitor preferences when interacting with the website. Where registration is required, the visitor’s email and a username will be stored on the server. How the Information is used The information is used to enhance the visitor’s experience when using the website to display personalized content and possibly advertising. E-mail addresses will not be sold, rented or leased to 3rd parties.
                            E-mail may be sent to inform you of news of our services or offers by us or our affiliates. Visitor Options If you have subscribed to one of our services, you may unsubscribe by following the instructions which are included in e-mail that you receive. You may be able to block cookies via your browser settings but this may prevent you from access to certain features of the website. Cookies are small digital signature files that are stored by your web browser that allow your preferences to be recorded when visiting the website. Also they may be used to track your return visits to the website. 3rd party advertising companies may also use cookies for tracking purposes.
                        </motion.div>
                    </div>
                    <div className="phrase mt-10 w-[80%] flex flex-col items-center justify-center">
                        <motion.div {...slideUp(showView)} className="p1 w-full opacity-80 text-start text-2xl font-bold">
                            Refund/Cancellation Policy
                        </motion.div>

                        <motion.div {...slideUp(showView)} className="mt-4 text-[#858585] font-medium">
                            You may cancel your subscription any time before the day payment is due on your next billing cycle. Unity Academy is not obligated to provide any refunds or credits for any reason, including, without limitation, satisfaction or failure to cancel your subscription prior to its automatic renewal. You are responsible for managing your subscription.
                            <br />
                            To cancel your Unity Academy Membership:
                            <br />    <br />
                            1) Login to the Unity Academy whop page by going to the user dashboard. You may have to use the drop down menu if you are on a mobile device.
                            <br />
                            2) Click “Cancel”. You will still have access to your membership until the end of your billing cycle. If you need help, please create a ticket in the server by going to the #speaktosupport channel, or email us at unityacademycorp@gmail.com.
                            <br />    <br />
                            Cancellation requests made through other means (e.g. leaving the Discord server) will not be accepted as a formal request for cancellation of your subscription. For additional help, contact us.

                            Any promotional rates that you were previously subjected to that are no longer offered will not be honored should you decide to repurchase our services at a later date.

                            Free trials: Your card will be automatically charged at the end of the trial period. If you are unsatisfied with our service for any reason, you can cancel before the end of the trial period to avoid being charged.

                            Subscriptions are non-refundable due to the nature of the services provided.
                        </motion.div>
                    </div>
                    <div className="phrase mt-10 w-[80%] mb-10 flex flex-col items-center justify-center">
                        <motion.div {...slideUp(showView)} className="p1 w-full opacity-80 text-start text-2xl font-bold">
                            Limitation of Liability
                        </motion.div>

                        <motion.div {...slideUp(showView)} className="mt-4 text-[#858585] font-medium">
                            IN NO EVENT WILL THE CONTENT CREATOR, ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SERVER, OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SERVER, EVEN IF THE CONTENT CREATOR OR ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            <br /><br />
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE LIABILITY OF THE CONTENT CREATOR AND ITS AFFILIATES, PARTNERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE CONTENT CREATOR FOR THE SERVER IN THE IMMEDIATELY PRECEDING TWELVE MONTHS, BUT IN NO CASE WILL SUCH LIABILITY TO YOU EXCEED $1000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE CONTENT CREATOR FOR THE SERVER, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM THE CONTENT CREATOR, REGARDLESS OF THE CAUSE OF ACTION. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                            <br /><br />
                            IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                        </motion.div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default Legal;