import * as React from "react";
import Nav from "../components/Navbar.tsx"
import "../styles/globals.css";
import data from "../data/courses.js";
import "./scrollbar.css"
function Course(props) {
    return (
        <div style={{ background: "linear-gradient(112deg, rgba(0, 0, 0, 0.00) 0%, #363636 105.71%)" }} className="flex flex-col text-white px-1 min-h-screen no-scrollbar">
            <div className="ahh">
                <Nav />
            </div>

            <div className="flex items-center justify-center max-md:flex-col pt-28 mx-10 max-md:mx-6 min-h-[100vh] w-full self-center max-w-[1600px] no-scrollbar">
                <div className="flex items-center flex-col grow">
                    <div className="max-w-[550px] flex flex-col max-md:items-center">

                        <a href={"/dashboard"} >
                            <div className="text-center cursor-pointer butt3 butt max-md:mb-4 text-sm max-md:text-base bg-transparent w-[100px] font-bold text-white rounded-[100px] border-white border-[2px] py-2.5  mt-10">
                                Back
                            </div>
                        </a>
                        <div className="text-5xl max-md:text-center max-md:text-3xl font-semibold mt-10 max-md:mt-0">
                            {data[props.course].heading}
                        </div>

                        <div className="mt-10 text-xl max-md:text-center">
                            {data[props.course].body}
                        </div>
                        <a href={data[props.course].link} target="_blank">
                            <div className="text-center max-md:w-full text-lg max-md:text-base bg-white max-w-[250px] font-bold text-black rounded-[100px] py-2.5 px-10 mt-10">
                                Start Learning
                            </div>
                        </a>
                        <div style={{ background: "linear-gradient(98deg, rgba(0, 0, 0, 0.57) 1.09%, rgba(32, 32, 32, 0.42) 100%)" }} className="max-md:w-full border border-2 rounded-[10px] border-[#DDDDDD54] max-w-[400px] mt-10">
                            <div className="py-4 px-4 text-xl">
                                Course Info
                            </div>

                            <hr className="opacity-50" />

                            <div className="flex justify-between py-3 px-4 mr-3">
                                <div>
                                    Length
                                </div>

                                <div>
                                    {data[props.course].length}
                                </div>
                            </div>

                            <hr className="opacity-50" />

                            <div className="flex justify-between py-3 px-4 mr-3">
                                <div>
                                    Modules
                                </div>

                                <div>
                                    {data[props.course].lessons.length}
                                </div>
                            </div>

                            <hr className="opacity-50" />

                            <div className="flex justify-between py-3 px-4 mr-3">
                                <div>
                                    Difficulty
                                </div>

                                <div>
                                    {data[props.course].difficulty}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pl-10 max-h-[700px] max-md:max-h-[100000px] max-md:pl-0 flex flex-col grow items-center h-[calc(100vh-120px)] max-md:h-full max-md:mt-10 max-md:pb-6">
                    <div className="flex flex-col max-w-[500px] h-full">
                        <img className="w-full rounded-xl border-2 border-[#DADADA]" src={data[props.course].img} />
                        <div className="text-lg mt-8 opacity-25">Courses Modules</div>
                        <div className="flex flex-col flex-1 overflow-y-auto mt-1.5 ">

                            {data[props.course].lessons.map((answer, i) => {
                                const j = i + 1;
                                return (
                                    <>
                                        <a href={data[props.course].link + "/lesson" + j} target="_blank">
                                            <div className="flex items-center text-lg border rounded-[18px] border-white border-solid mb-4 py-2.5 px-5">
                                                <img className="h-8 w-8 mr-4" src="images/play.png" />
                                                {i + 1}. {"" + answer}
                                            </div></a>
                                    </>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Course;