import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function Oracle1() {
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)}>
      <div className="relative flex flex-col items-stretch mx-4 mt-[270px] max-w-[1250px] mx-auto max-md:mt-20">
        <div style={{ background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)" }} className="absolute left-[18%] top-[100px] rounded-[1143px] opacity-[0.04] w-2/3 h-[260px]" />
        <div className="w-full max-md:max-w-full">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 justify-center">

            <div className="flex flex-col items-stretch w-[52%] max-md:w-full max-md:ml-0">
              <div className="fuck flex flex-col items-stretch px-10 max-md:max-w-full max-md:mt-10">
                <div className="text-white text-3xl max-w-[500px] font-bold max-md:max-w-full max-md:text-2xl">
                  Identify Institutional Volume Levels
                </div>
                <div className="text-white text-xl max-md:text-lg max-md:font-medium font-semibold mt-12 max-md:max-w-full max-md:mt-10">
                  Access advanced analysis, providing the earliest indications of
                  price movement. Designed to provide a cutting edge simplified
                  experience on your charts with an emphasis on risk management!
                </div>

                <div className="fuck mt-10 butt text-neutral-800 cursor-pointer text-center text-xl max-md:text-base font-extrabold whitespace-nowrap bg-stone-50 justify-center items-stretch px-14 py-3.5 max-md:py-3 rounded-[30px] self-start  max-md:px-10">
                <a href="/OraclePricing">
                Unlock Access
                </a>
              </div>
              </div>
            </div>

            <div className="ml-12 more max-md:ml-0 max-w-[500px] max-md:mt-20 max-md:max-w-[90%] max-md:self-center">
              <img src="images/log6.webp" />
            </div>
          </div>
        </div>
      </div>
      /</motion.div>
      );
}

      export default Oracle1;