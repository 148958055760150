import * as React from 'react';
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import CountUp from 'react-countup';
import { Dispatch, SetStateAction, FC, useContext } from "react";
function UnityAcademy() {
    const { showView } = useContext(ViewContext);
    return (
        <motion.div {...slideUp(showView)}>
            <div className="mb-24 max-w-[1291px] mx-4">
                <div style={{ background: "linear-gradient(107deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.01) 0.01%, rgba(189, 189, 189, 0.03) 100%)" }} className='mb-20 mt-16 max-md:mt-10 pb-12 px-8 py-4 rounded-[20px] flex items-center justify-center '>
                    <div className="relative flex w-full  flex-col items-stretch mt-10 mb-12 max-md:max-w-full max-md:my-10">
                        <div className='w-full'>
                            <div className="gap-5 flex max-[900px]:flex-col max-[900px]:gap-10 flex items-center justify-center">
                                <div className="flex flex-col items-stretch w-[55%] max-[900px]:w-full max-md:ml-0">
                                    <div className="flex flex-col mt-16 w-full max-w-[550px] max-md:mt-10">
                                        <div className="text-white text-[42px] max-[900px]:text-center whitespace-nowrap font-medium self-stretch max-md:max-w-full max-md:text-2xl">
                                            Unity Academy
                                        </div>
                                        <div className="text-white text-opacity-80 max-[900px]:text-center text-xl self-stretch mt-8 max-md:max-w-full max-md:mt-10">
                                            Our professional Technical Analysts produce accurate signals
                                            exclusively for members. Receive real-time trade notifications
                                            and act on them.
                                        </div>
                                        <div className="text-white text-opacity-80 max-[900px]:text-center text-xl self-stretch mt-8 max-md:max-w-full">
                                            Select your signal. Place your trade.
                                        </div>
                                        <a href='https://whop.com/unity-academy/' className="butt text-black cursor-pointer max-[900px]:mx-auto text-center text-xl font-bold max-w-full bg-white w-[350px] justify-center items-center mt-12 px-16 py-2.5 rounded-[30px] self-start max-md:mt-10 max-md:px-5">
                                           Become a Member
                                        </a>
                                    </div>
                                </div>
                                <img className='more aspect-[1.24] max-[900px]:w-full max-w-[500px] object-contain object-center w-[50%] min-w-[200px]' srcSet="images/phone.webp" />
                            </div>
                        </div>
                        <motion.div {...slideUp(showView)}>
                            <div className='remove'>
                                <div className="mt-28 max-md:max-w-full max-md:mt-1">
                                    <div className="gap-4 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-custom-grey flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/1.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-white text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    Trade Setups
                                                </div>
                                                <div className="text-neutral-300 text-center font-medium mt-6 max-md:mt-10">
                                                    Trade seamlessly with experts for high win-rate Crypto signals
                                                    and analysis.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-orange-50 flex grow flex-col items-stretch w-full pl-7 pr-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/2.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-stone-900 text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    Daily Live Classes
                                                </div>
                                                <div className="text-zinc-800 text-center font-medium mt-6 max-md:mt-10">
                                                    Attend Daily live lessons cover beginner to advanced topics with
                                                    analyst feedback.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-custom-grey flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/3.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-white text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    A-Z Education
                                                </div>
                                                <div className="text-neutral-300 text-center text-lg font-medium mt-6 max-md:mt-10">
                                                    Learn A-Z trading, develop habits for consistent profit, with
                                                    constant support.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-orange-50 flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/4.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-stone-900 text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    Community
                                                </div>
                                                <div className="text-zinc-800 text-center font-medium mt-6 max-md:mt-10">
                                                    Inclusive community welcomes all traders for unique,
                                                    freedom-driven education system.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 max-md:max-w-full max-md:mt-1">
                                    <div className="gap-4 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-orange-50 flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/5.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-stone-900 text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    Open 24 Hours
                                                </div>
                                                <div className="text-zinc-800 text-center font-medium mt-6 max-md:mt-10">
                                                    Engage in our active, friendly community with members, analysts, and support.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-custom-grey flex grow flex-col items-stretch w-full pl-7 pr-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/6.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-white text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    Professional Help
                                                </div>
                                                <div className="text-neutral-300 text-center font-medium mt-6 max-md:mt-10">
                                                    Decades-experienced analysts answer questions, 24/7 support for all your needs.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-orange-50 flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/7.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-stone-900 text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    OracleAlgo
                                                </div>
                                                <div className="text-zinc-800 text-center text-lg font-medium mt-6 max-md:mt-10">
                                                    Unlock precise analysis with OracleAlgo, offering early price action indications on charts.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                            <div className="shadow-sm bg-custom-grey flex grow flex-col items-stretch w-full px-4 py-8 rounded-[39px] max-md:mt-7 max-md:px-5">
                                                <img srcSet="images/8.png" className="object-center w-16 h-16 self-center" />
                                                <div className="text-white text-center text-lg font-bold self-center max-w-[273px] mt-6 max-md:mt-10">
                                                    UnityAcademy Pro
                                                </div>
                                                <div className="text-neutral-300 text-center font-medium mt-6 max-md:mt-10">
                                                Access UnityAcademy Pro which features 130 modules, live private mentorship classes + VIP signals.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
                <motion.div {...slideUp(showView)}>
                <div style={{ background: "#131313" }} className="flex flex-col justify-center items-center px-4 pt-24 pb-210 max-md:pb-10 rounded-[29px]">
                    <div className="flex w-full max-w-[1129px] flex-col mb-4 max-md:max-w-full max-md:mb-10">
                        <div className="px-5">
                            <div className="gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4">
                                <div className="min-w-[260px] flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                    <div style={{ background: "linear-gradient(140deg, rgba(237, 237, 237, 0.06) 0%, rgba(217, 217, 217, 0.05) 100.6%)" }} className="relative shadow-sm flex grow flex-col items-center w-full px-2 py-10 rounded-2xl max-md:mt-10 max-md:px-5">
                                        <div className='absolute top-[-40px] rounded-[100px] bg-[#1F1F1F]'>
                                            <img className=' h-24 w-24' srcSet="images/l-1.png" />
                                        </div>
                                        <div className="text-white text-2xl font-bold whitespace-nowrap mt-8">
                                        <CountUp enableScrollSpy start={0} end={87.9} />%
                                        </div>
                                        <div className="text-white text-center font-medium self-stretch mt-4 mx-4">
                                            of our analysts’ trades have been successful since our inception.
                                        </div>
                                    </div>
                                </div>
                                <div className="min-w-[260px] flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                    <div style={{ background: "linear-gradient(140deg, rgba(237, 237, 237, 0.06) 0%, rgba(217, 217, 217, 0.05) 100.6%)" }} className="relative shadow-sm flex grow flex-col items-center w-full px-8 py-10 rounded-3xl max-md:mt-10 max-md:px-5">
                                        <div className='absolute top-[-40px] rounded-[100px] bg-[#1F1F1F]'>
                                            <img className=' h-24 w-24' srcSet="images/l-2.png" />
                                        </div>
                                        <div className="text-white text-center text-2xl font-bold whitespace-nowrap mt-8">
                                        <CountUp enableScrollSpy start={0} end={16945} />
                                        </div>
                                        <div className="text-white text-center font-medium self-stretch mt-4 mx-4">
                                            of our members have posted testimonials of their profits earned.
                                        </div>
                                    </div>
                                </div>
                                <div className="min-w-[260px] flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                    <div style={{ background: "linear-gradient(140deg, rgba(237, 237, 237, 0.06) 0%, rgba(217, 217, 217, 0.05) 100.6%)" }} className="relative shadow-sm flex grow flex-col items-center w-full px-8 py-10 rounded-3xl max-md:mt-10 max-md:px-5">
                                        <div className='absolute top-[-40px] rounded-[100px] bg-[#1F1F1F]'>
                                            <img className=' h-24 w-24' srcSet="images/l-3.png" />
                                        </div>
                                        <div className="text-white text-center text-2xl font-bold whitespace-nowrap mt-8">
                                        <CountUp enableScrollSpy start={0} end={50} />
                                        </div>
                                        <div className="text-white text-center font-medium self-stretch mt-4 mx-4">
                                            of our staff members and exclusive analysts to help you succeed.
                                        </div>
                                    </div>
                                </div>
                                <div className="min-w-[260px] flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                    <div style={{ background: "linear-gradient(140deg, rgba(237, 237, 237, 0.06) 0%, rgba(217, 217, 217, 0.05) 100.6%)" }} className="relative shadow-sm flex grow flex-col items-center w-full px-2 py-10 rounded-3xl max-md:mt-10 max-md:px-5">
                                        <div className='absolute top-[-40px] rounded-[100px] bg-[#1F1F1F]'>
                                            <img className=' h-24 w-24' srcSet="images/l-4.png" />
                                        </div>
                                        <div className="text-white text-center text-2xl font-bold whitespace-nowrap mt-8">
                                        <CountUp enableScrollSpy start={0} end={50} />
                                        </div>
                                        <div className="text-white text-center font-medium self-stretch mt-4 mx-4">
                                            live trading and educational sessions hosted every month.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="flex items-stretch justify-between gap-5 mt-14 max-md:max-w-full flex-wrap max-md:mt-10">
                            <a href='#oracleAlgoPricing' className="butt3 butt text-white cursor-pointer text-lg font-semibold text-center grow justify-center items-center flex px-6 py-2.5 rounded-[29px] border-2 border-solid border-orange-50 max-md:px-5">
                             Access Oracle Algo
                            </a>
                            <a href="#pricing" className="butt text-black cursor-pointer text-lg font-semibold text-center bg-orange-50 grow flex items-center justify-center px-6 py-2.5 rounded-[29px] max-md:px-5">
                            Access UnityAcademy Pro
                            </a>
                            <a href='https://whop.com/unity-academy/' className="butt3 butt text-white cursor-pointer text-lg font-semibold text-center grow flex items-center justify-center px-6 py-2.5 rounded-[29px] border-2 border-solid border-orange-50 max-md:px-5">
                                Become a Member 
                            </a>
                        </div>
                    </div>
                </div>
               </motion.div> 
            </div>
        </motion.div>
    );
}

export default UnityAcademy;