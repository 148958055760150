import * as React from "react";
import { Accordion } from 'flowbite-react';
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function JoinCommunity(){
    const { showView } = useContext(ViewContext);
    return(
        <motion.div {...slideUp(showView)}>
        <div className="flex flex-col justify-center items-center mb-20 mt-20 w-full max-w-[650px]">
            <div style={{background: "linear-gradient(98deg, rgba(0, 0, 0, 0.31) 1.09%, rgba(104, 104, 104, 0.29) 100%)"}} className="flex flex-col justify-center w-2/3 max-md:w-[85%] items-center px-6 py-8 rounded-[29px] border-2 border-solid border-zinc-500 border-opacity-60">
                <div className="flex w-full flex-col items-stretch mt-5 mb-2">
                    <div className="text-white text-center text-3xl font-bold max-md:text-2xl">
                        Join Our Community
                    </div>
                    <div className="text-white text-center text-xl font-bold self-center mt-11 max-md:mt-10">
                        Official Discord partner
                    </div>
                    <a href="https://discord.gg/unityacademycorp" className="butt text-black cursor-pointer text-center text-lg font-bold max-w-[80%] bg-white self-center w-full justify-center items-center mt-12 px-16 py-3.5 rounded-[41px] max-md:mt-10 max-md:px-5">
                        Join Discord
                    </a>
                </div>
            </div>
            <div className="self-center flex w-full max-w-[1109px] justify-center items-center gap-5 mt-20 px-5 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
                <div className="text-white text-2xl max-md:text-lg font-bold ">
                Have A Question? Get in touch
                </div>
                <a href="https://discord.gg/unityacademycorp" className="butt text-black cursor-pointer text-center text-xl max-md:text-lg font-bold bg-white self-stretch grow justify-center items-center ml-6 max-md:max-w-[80%] max-w-[350px] px-6 py-2.5 rounded-[41px]">
                    Contact us
                </a>
            </div>
        </div>
        /</motion.div>
    );
}

export default JoinCommunity;