import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function TradingMastery2() {
  const { showView } = useContext(ViewContext);
  return (
    <div id="education" className="scroll-my-36 max-md:scroll-my-28 fuckthis relative max-w-[90%] ml-[5%] flex flex-col px-5 mb-40 mx-10 max-md:px-2">
      <div style={{ background: "linear-gradient(180deg, rgba(255, 255, 255, 0.27) 0%, rgba(255, 44, 44, 0.27) 100%, rgba(211, 211, 211, 0.48) 100%)", filter: "blur(67.1500015258789px)" }} className="absolute top-20 w-full h-[1116px] rounded-[1682px] opacity-[0.05]" />

      <div className="noo text-white leading-[115%] text-center text-5xl font-bold self-center max-w-[964px] max-md:max-w-full max-md:text-3xl">
        UNITY ACADEMY PRO
      </div>
      <div className="noo self-center flex items-stretch center-items justify-between gap-5 mt-8 max-md:max-w-full max-md:flex-wrap max-md:mt-10">

        <div className="man text-white text-center max-md:text-sm max-md:px-4 font-semibold grow justify-center items-stretch px-11 py-2 rounded-[35px] border-2 border-solid border-white ">
          Learn to trade from A-Z
        </div>

        <div className="man text-white text-center max-md:text-sm max-md:px-4 font-semibold grow justify-center items-stretch px-14 py-2 rounded-[35px] border-2 border-solid border-white max-md:max-w-full">
          Answer to every question related to trading
        </div>

      </div>
      <div className="noo self-center flex items-stretch justify-between gap-5 mt-6 max-md:max-w-full max-md:flex-wrap">

        <div className="man text-white text-center max-md:text-sm max-md:px-4 font-semibold grow justify-center items-stretch px-14 py-2 rounded-[35px] border-2 border-solid border-white max-md:max-w-full">
          Cultivate good habits to become profitable
        </div>

        <div className="man text-white text-center max-md:text-sm max-md:px-4 font-semibold grow justify-center items-stretch px-14 py-2 rounded-[35px] border-2 border-solid border-white max-md:max-w-full">
          Identify bad habits leading to losses
        </div>

      </div>

      <div className="flex flex-col">
        <div className="self-stretch w-full mt-10 max-md:max-w-full max-md:mt-10">

          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">

            <div className="flex flex-col items-stretch w-3/12 justify-between max-md:w-full max-md:ml-0">
              <div className="nig shadow-sm bg-custom-grey flex grow flex-col items-start w-full pl-6 pr-12 py-8 rounded-[36px] max-md:mt-9 max-md:px-5">
                <img className="h-12 w-12" srcSet="images/t-1.png" />
                <div className="man text-white text-xl font-bold self-stretch mt-4">
                  VIP Trade Signals
                </div>
                <div className="man remove text-zinc-300 font-medium self-stretch mt-2">
                  Access VIP high win rate signals from our best specialist crypto analysts.
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="nig shadow-sm bg-custom-grey flex grow flex-col items-start w-full py-8 px-6 rounded-[36px] max-md:mt-9 max-md:px-5">
                <img className="h-12 w-12" srcSet="images/t-2.png" />
                <div className="man text-white text-xl font-bold self-stretch mt-4">
                Academy Pro Education
                </div>
                <div className="man remove text-zinc-300 font-medium self-stretch mt-2">
                130+ Mentorship course topic modules featuring everything there is to know about mastering trading as well as the psychology behind it.
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="nig shadow-sm bg-custom-grey flex grow flex-col items-start w-full py-8 px-6 rounded-[36px] max-md:mt-9 max-md:px-5">
                <img className="h-12 w-12" srcSet="images/t-3.png" />
                <div className="man text-white text-xl font-bold self-stretch mt-4">
                  Private 1-1 Help
                </div>
                <div className="man remove text-zinc-300 font-medium self-stretch mt-2">
                  Access weekly
                  private mentorship
                  classes with our
                  specialist analysts.
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="nig shadow-sm bg-custom-grey flex grow flex-col items-start w-full pl-6 pr-12 py-8 rounded-[36px] max-md:mt-9 max-md:px-5">
                <img className="h-12 w-12" srcSet="images/t-4.png" />
                <div className="man text-white text-xl font-bold self-stretch mt-4">
                High Success Rate Strategies
                </div>
                <div className="man remove text-zinc-300 font-medium self-stretch mt-2">
                Master trading the charts with step by step with industry secrets to become consistently profitable.
                </div>
              </div>
            </div>
          </div>

        </div>

        
      </div>


    </div>
  );
}

export default TradingMastery2;