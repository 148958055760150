import React, { useRef, useState } from "react";
import data from "../data/courses.js"
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function FeaturedCourses() {
    const { showView } = useContext(ViewContext);
    const [state, setState] = useState({
        showAllCourses: true,
        set: data[1]
    });

    const topRef = useRef(null);
    const scroll = () => {
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <motion.div {...slideUp(showView)}>
            <div className=" here relative flex flex-col items-center ml-[4.7%] mx-6 max-w-[90%]">
                <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />
                <div ref={topRef} className="text-white scroll-my-28 max-md:scroll-my-20 text-center text-5xl font-medium max-md:text-5xl">
                    Featured Courses
                </div>

                <div className={state.showAllCourses == true ? "inline-block animate-fade" : "hidden"}>
                <motion.div {...slideUp(showView)}>
                    <div className="self-stretch w-full mt-14 max-md:mt-6">
                   
                            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">

                                <div className="flex flex-col items-stretch w-6/12 max-md:w-[90%] max-md:mx-auto">
                                    <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                        <div className="text-zinc-300 text-3xl font-medium mt-3 max-md:max-w-full">
                                            Trading Introduction
                                        </div>
                                        <div className="flex items-stretch justify-between gap-5 mt-5 self-start">
                                            <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                Beginner
                                            </div>
                                            <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                24 Lessons
                                            </div>
                                        </div>
                                        <div className="text-white text-lg mt-10 max-md:max-w-full">
                                            Understand all of the key fundamental foundations of the trading
                                            basics + much more with our Trading Introduction Course.
                                        </div>
                                        <div onClick={() => { setState({ showAllCourses: false, set: data[1] }); scroll() }} className="flex max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                            <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                            <div className="text-white cursor-pointer text-xl font-medium my-auto mr-4">
                                                Show Lessons
                                            </div>
                                            <div className="butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                Start Course
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="flex flex-col items-stretch w-6/12 max-md:w-[90%] max-md:mx-auto">
                                    <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                        <div className="text-zinc-300 text-3xl font-medium mt-3 max-md:max-w-full">
                                            Start Trading
                                        </div>
                                        <div className="flex items-stretch justify-between gap-5 mt-5 self-start">
                                            <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                Intermediate
                                            </div>
                                            <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                28 Lessons
                                            </div>
                                        </div>
                                        <div className="text-white text-lg mt-10 max-md:max-w-full">
                                            Receive insights on chart analysis, trading plans, execution,
                                            risk management + much more with our Start Trading Course.
                                        </div>
                                        <div onClick={() => { setState({ showAllCourses: false, set: data[2] }); scroll() }} className="flex max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                            <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                            <div className="text-white cursor-pointer text-xl font-medium my-auto mr-4">
                                                Show Lessons
                                            </div>
                                            <div className="butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                Start Course
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                    </div>
                    </motion.div>
                    <motion.div {...slideUp(showView)}>
                    <div className="self-stretch w-full mt-8 max-md:mt-0 max-md:max-w-full">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-6/12 max-md:w-[90%] max-md:mx-auto">
                                <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                    <div className="text-zinc-300 text-3xl font-medium mt-3 max-md:max-w-full">
                                        Master Trading
                                    </div>
                                    <div className="flex items-stretch justify-between gap-5 mt-4 self-start">
                                        <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                            Advanced
                                        </div>
                                        <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                            28 Lessons
                                        </div>
                                    </div>
                                    <div className="text-white text-lg mt-10 max-md:max-w-full">
                                        Unlock access to high win rate strategies, theory concepts, key
                                        trading edges, psychological advantages + much more with our
                                        Master Trading Course.
                                    </div>
                                    <div onClick={() => { setState({ showAllCourses: false, set: data[3] }); scroll() }} className="flex max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                        <img className='w-7 h-7 cursor-pointer my-auto' src="images/plus.png" />
                                        <div className="text-white cursor-pointer text-lg font-medium my-auto mr-4">
                                            Show Lessons
                                        </div>
                                        <div className="butt text-black cursor-pointer text-center text-lg font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                            Start Course
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-col items-stretch w-6/12 max-md:w-[90%] max-md:mx-auto">
                                <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                    <div className="text-zinc-300 text-3xl font-medium mt-3 max-md:max-w-full">
                                        Self Improvement
                                    </div>
                                    <div className="flex items-stretch justify-between gap-5 mt-4 self-start">
                                        <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                            Personal
                                        </div>
                                        <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                            50 Lessons
                                        </div>
                                    </div>
                                    <div className="text-white text-lg mt-11 max-md:max-w-full">
                                        Unlock access to self mastery mind conditioning, stoicism, self
                                        control, fitness training + much more with our Self Improvement
                                        Course.
                                    </div>
                                    <div onClick={() => { setState({ showAllCourses: false, set: data[4] }); scroll() }} className="flex max-w-full items-stretch justify-between gap-3.5 mt-12 self-start max-md:flex-wrap max-md:justify-center">
                                        <img className='w-7 h-7 cursor-pointer my-auto' src="images/plus.png" />
                                        <div className="text-white cursor-pointer text-lg font-medium my-auto mr-4">
                                            Show Lessons
                                        </div>
                                        <div className="butt text-black cursor-pointer text-center text-lg font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                            Start Course
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </motion.div>
                </div>


                <div style={{ background: "linear-gradient(180deg, rgba(21, 21, 21, 0.73) 0%, rgba(24, 24, 24, 0.71) 100%)" }} className={(state.showAllCourses === true ? "hidden" : "flex flex-col animate-fade") + " mx-8 max-md:mx-0 justify-between w-full mt-10 border border-solid border-2 border-white rounded-[30px] py-8 px-12 max-md:px-4"}>
                    <div>
                        <div className="text-3xl text-white max-md:text-center">
                            {state.set.heading}
                        </div>
                        <div className="flex items-stretch gap-5 mt-8 self-start">
                            {state.set.highlights.map((txt, i) => {
                                return (
                                    <div className="text-stone-300 max-w-[220px] text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                        {txt}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="text-white text-lg mt-10 max-md:max-w-full max-w-[600px]">
                            {state.set.body}
                        </div>
                        <div className="flex w-[452px] max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                            <img onClick={() => { setState({ showAllCourses: true, set: data[1] }); scroll() }} className='w-7 h-7 cursor-pointer my-auto' src="images/hide.png" />
                            <div onClick={() => { setState({ showAllCourses: true, set: data[1] }); scroll() }} className="text-white cursor-pointer text-lg font-medium my-auto mr-2">
                                Hide Lessons
                            </div>
                            <a href='#pricing' className="butt text-black cursor-pointer text-center text-lg font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                Start Course
                            </a>
                        </div>
                    </div>
                    <div className="text-white grid max-md:mx-auto grid-flow-row-dense grid-cols-4 max-md:grid-cols-2 mt-10">
                        {state.set.lessons.map((answer, i) => {
                            return (
                                <div className="mt-3 mx-2">
                                    {i + 1}.{" " + answer}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            </motion.div>
    );
}

export default FeaturedCourses;