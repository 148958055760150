import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function TradingMastery1(){
    const { showView } = useContext(ViewContext);
    return(
        <motion.div {...slideUp(showView)}>
        <div id="UnityAcademyPro" className="scroll-my-36 max-md:scroll-my-28 mt-28 max-md:mt-20 mx-10 max-md:mx-3 relative">
            <div style={{background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)"}} className="top-36 absolute w-full h-[300px] rounded-[1381px] opacity-[0.05]"/>
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch justify-between">
                <div className="flex flex-col items-stretch max-w-[600px] max-md:w-full max-md:ml-0">
                    <div className="fuck flex flex-col my-auto px-5 items-start max-md:max-w-full max-md:mt-10">
                        <div className="text-white text-4xl font-medium uppercase self-stretch max-md:max-w-full max-md:text-3xl">
                       UnityAcademy Pro
                        </div>
                        <div className="text-stone-300 text-xl max-md:text-lg font-semibold self-stretch mt-14 max-md:max-w-full max-md:mt-10">
                            Watch, read, learn & profit from our custom made education
                            application + mentorship. Be prepared to have your psychology,
                            profitability & overall life dramatically improved.
                        </div>
                        <a href="/dashboard" className="fuck butt text-xl cursor-pointer font-bold text-black bg-white justify-center items-center flex w-[279px] shrink-0 max-w-full h-[53px] flex-col mt-16 rounded-[30px] max-md:mt-10">
                            Start Learning
                        </a>
                    </div>
                </div>
                <motion.div {...imageLoadAnimation(showView)}>
                <img srcSet="images/lap2.webp" className="max-[770px]:mt-10 px-8 w-full more max-w-[650px] max-md:min-w-[300px] min-w-[400px] aspect-[1.48] object-contain object-center " />
                </motion.div>
            </div>
        </div>
    </motion.div>
    );
}

export default TradingMastery1;