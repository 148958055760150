import * as React from "react";
import { Accordion } from 'flowbite-react';
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function FrequentlyAskedQuestions() {
    const { showView } = useContext(ViewContext);
    return (
        <motion.div {...slideUp(showView)}>
            <div className="flex flex-col px-5 w-[55rem] max-md:w-[25rem] m-auto mt-40 max-md:mt-24 w-full">
                <div style={{ background: "linear-gradient(90deg, #3D3D3D 0%, #000 100%)" }} className="mb-10 text-neutral-200 text-center text-xl font-medium max-w-[500px] border self-center w-full justify-center items-center px-20 py-2.5 rounded-[29px] border-solid border-zinc-400 max-md:px-5">
                    Frequently Asked Questions
                </div>

                <Accordion className="small border-none w-[90%] ml-[4%] duration-75 transition-all" flush>
                    <Accordion.Panel>
                        <Accordion.Title className="duration-75 transition-all py-7 focus:bg-transparent bg-transparent hover:bg-transparent text-white text-lg font-medium">
                            How Does it Work?
                        </Accordion.Title>
                        <Accordion.Content>
                            <div className="text-white text-opacity-60 font-light leading-6 self-stretch w-full max-md:max-w-full">
                                Once you purchase a plan you are prompted with a link to get instant
                                access to our 2 premium indicators directly on your TradingView charts.
                                It works fully on the free plan of TradingView.
                                You will also be able to connect your Discord account to join our members area, a full
                                community experience, and active product updates. If you're having any
                                issues, email your usernames to support@unityacademy.com.
                                <br />
                                <br />
                                You will also
                                be able to connect your Discord account to join our members area, a full
                                community experience, and active product updates. If you're having any
                                issues, email your usernames to support@unityacademy.com
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="py-7 focus:bg-transparent bg-transparent hover:bg-transparent text-white text-lg font-medium leading-6 grow shrink basis-auto">
                            What markets can I use it With?
                        </Accordion.Title>
                        <Accordion.Content>
                            <div className="text-white text-opacity-60 font-light leading-6 self-stretch w-full max-md:max-w-full">
                                Once you purchase a plan you are prompted with a link to get instant access to our 2 premium indicators directly on your TradingView charts. It works fully on the free plan of TradingView. ‍ You will also be able to connect your Discord account to join our members area, a full community experience, and active product updates. If you're having any issues, email your usernames to support@unityacademy.com.
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="py-7 focus:bg-transparent bg-transparent hover:bg-transparent text-white text-lg font-medium leading-6 grow shrink basis-auto">
                            Can I win every trade using this?
                        </Accordion.Title>
                        <Accordion.Content>
                            <div className="text-white text-opacity-60 font-light leading-6 self-stretch w-full max-md:max-w-full">
                                Absolutely not. There is no indicator, "algorithm", "system", or strategy to give you the power to predict markets with a guaranteed % accuracy. ‍ The premium indicators cannot guarantee you profits or make you a better trader / investor alone. ‍ We do not recommend blindly following any indicator. Trading itself is risky, most day traders lose money, and past performance does not guarantee future results.
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>
            /</motion.div>
            );
}

            export default FrequentlyAskedQuestions;