import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function JoinUnity() {
    const { showView } = useContext(ViewContext);
    return (
        <div className="flex flex-col items-center px-5  max-md:mb-20 mx-10 max-md:mx-1">

            <img className="h-24" srcSet="images/unity.png" />
            <div className="text-white text-opacity-80 text-center text-3xl font-semibold max-w-[600px] leading-[120%] mt-6 max-md:max-w-full max-md:text-2xl max-md:mt-10">
                Making money trading is a skill.
                We will teach you how to master it.
            </div>

            <div className="shadow-sm bg-custom-grey self-stretch w-full mt-10 px-20 max-md:px-6 py-12 rounded-[38px] max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="gap-5 flex justify-between max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex relative flex-col items-stretch max-md:w-full max-md:ml-0">
                        <div style={{ background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)" }} className="h-1/3 w-1/2 rounded-[540px] opacity-[0.04] absolute top-1/3" />
                        <div className="flex flex-col my-auto max-md:max-w-full max-md:mt-10">
                            <div className="text-white text-3xl max-md:text-center font-medium self-stretch max-md:max-w-full max-md:text-2xl">
                                Daily trades, Daily Lessons
                            </div>
                            <div className="max-w-[600px] max-md:text-center text-white text-opacity-80 text-xl self-stretch mt-10 max-md:max-w-full max-md:mt-10">
                                World-class advanced training and mentoring from our team of
                                industry specialists.
                            </div>
                            <div className="text-white max-md:text-center text-opacity-80 text-xl self-stretch mt-4 max-md:max-w-full">
                                High win-rate Crypto & Forex trading signals
                            </div>
                            <a href="https://discord.gg/unityacademycorp" className="butt text-black max-md:self-center cursor-pointer text-center text-lg font-bold bg-white justify-center items-stretch mt-12 px-16 py-2.5 rounded-[30px] self-start max-md:mt-10 max-md:px-5">
                                Learn How to Trade
                            </a>
                        </div>
                    </div>
                    <div className="my-auto ml-24 flex flex-col items-center ml-5 max-md:w-full max-md:mt-4 max-md:ml-0">
                        <img className="w-[24vw] more min-w-[220px] max-md:mt-10" srcSet="images/desktop.png" />
                    </div>
                </div>
            </div>

            <motion.div {...slideUp(showView)}>
                <div className="shadow-sm bg-custom-grey self-stretch flex w-full flex-col justify-center items-center mt-20 px-16 py-12 rounded-[38px] max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <div className="w-full max-w-[1268px] max-md:max-w-full">
                        <div className="gap-5 relative flex items-center max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div style={{ background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)" }} className="right-0 h-1/3 w-1/2 rounded-[540px] opacity-[0.04] absolute top-1/3" />
                            <div className="flex justify-center items-stretch w-[51%] max-md:w-full max-md:ml-0">
                                <img className="pr-12 more max-md:pr-0 w-[27vw] min-w-[220px]" srcSet="images/unityAcademy.png" />
                            </div>
                            <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="flex flex-col my-auto items-start max-md:max-w-full max-md:mt-10">
                                    <div className="text-white text-3xl max-md:text-center font-medium self-stretch max-md:max-w-full max-md:text-2xl">
                                        Private Network
                                    </div>
                                    <div className="text-white max-md:text-center text-opacity-80 text-xl self-stretch mt-10 max-md:max-w-full max-md:mt-10">
                                        Join a community where you can meet like-minded friends on
                                        your journey, learn from successful individuals, and network
                                        with over 30,000 people
                                    </div>
                                    <a href="https://discord.gg/unityacademycorp" className="butt text-black max-md:mx-auto cursor-pointer text-center text-lg font-bold bg-white justify-center items-stretch mt-12 px-14 py-2.5 rounded-[30px] max-md:mt-10 max-md:px-5">
                                        Join our Community
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                /</motion.div>
            <motion.div {...slideUp(showView)}>
                <div className="shadow-sm bg-custom-grey self-stretch flex w-full flex-col justify-center items-center mt-20 px-16 pb-6 rounded-[38px] max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <div className="flex relative w-full max-w-[1245px] justify-between gap-5 mt-12 mb-9 items-start max-md:max-w-full flex-wrap max-md:justify-center max-md:mt-10">
                        <div style={{ background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)" }} className="right-0 h-1/3 w-1/2 rounded-[540px] opacity-[0.04] absolute top-1/3" />
                        <div className="flex mt-8 grow basis-[0%] flex-col items-start max-md:max-w-full">
                            <div className="text-white text-3xl max-md:text-center font-medium self-stretch max-md:max-w-full max-md:text-2xl">
                                Access to Industry Specialist Traders
                            </div>
                            <div className="text-white text-opacity-80 max-md:text-center text-xl self-stretch mt-10 max-md:max-w-full max-md:text-lg max-md:mt-10">
                                Enlist the guidance of hyper-successful trading experts who are
                                professional analysts, ensuring mentorship at every step of your
                                journey and mastery of the key skills necessary to maximize your
                                income.
                            </div>
                            <a href="/team" className="butt text-black cursor-pointer max-md:self-center text-center text-lg font-bold max-w-full bg-white w-[279px] justify-center items-center mt-14 px-16 py-2.5 rounded-[30px] max-md:mt-10 max-md:px-5">
                                Meet the Team
                            </a>
                        </div>
                        <div className="self-stretch flex grow basis-[0%] flex-col items-stretch mt-8">
                            <div className="flex justify-between gap-5 items-start max-md:justify-center">
                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center p-0.5 rounded-[50%]">
                                        <img srcSet="images/m1.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        Caleb
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Founder
                                    </div>
                                </div>

                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center p-0.5 rounded-[50%]">
                                        <img srcSet="images/m2.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        RZ
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Analyst
                                    </div>
                                </div>

                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center p-0.5 rounded-[50%]">
                                        <img srcSet="images/m3.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        Neil
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Analyst
                                    </div>
                                </div>

                                
                            </div>

                            <div className="flex justify-between gap-5 mt-8 items-start max-md:justify-center max-md:mt-10">
                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center px-0.5 py-1 rounded-[50%]">
                                        <img srcSet="images/m5.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        Arsh
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Analyst
                                    </div>
                                </div>

                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center px-0.5 py-1 rounded-[50%]">
                                        <img srcSet="images/m6.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        Chrollo
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Analyst
                                    </div>
                                </div>

                                <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                                    <div className="flex flex-col justify-center items-center p-0.5 rounded-[50%]">
                                        <img srcSet="images/m4.png" className="w-[78px] h-[78px] aspect-1 object-contain object-center" />
                                    </div>
                                    <div className="text-white text-base font-extrabold self-center whitespace-nowrap mt-3">
                                        Chong
                                    </div>
                                    <div className="text-neutral-500 text-base font-medium self-center whitespace-nowrap">
                                        Analyst
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                /</motion.div>
        </div >

    );
}

export default JoinUnity;