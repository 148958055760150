import React, { useState } from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
const data = {

  1: {
    heading: "Advanced Charting Tools",
    body: "Streamlines charting by automatically plotting crucial data on your chart. A time-saver that eliminates guesswork, a powerful confluence tool, highlighting key price reversal levels such as supply & demand zones, order blocks, fair value gaps, market structure mapping + breakout probability.",
    image: "images/c1.webp"
  },

  2: {
    heading: "Advanced Oscillator",
    body: "Gain exclusive insights into price action market momentum and trade the market sentiment with confidence with our delta volume oscillator, VEMA ribbons and bands to detect key levels as well as bullish & bearish divergences to signal potential trend reversals.",
    image: "images/c2.webp"
  },

  3: {
    heading: "Advanced Trend",
    body: "Elevate your trading game and receive clarity on the charts trend strength, volatility, momentum, volume & EMA's with our unique trend table, trend Lines, trend Channel, trend Candles which is your all-in-one solution for mastering price action and staying ahead of market trends.",
    image: "images/c3.webp"
  }
}

function Oracle1() {
  const [state, setState] = useState({
    heading: data[1].heading,
    body: data[1].body,
    image: data[1].image,
    active: 1
  });
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)}>
      <div className="shadow-sm flex flex-col items-stretch py-3 mx-auto w-[90%] rounded-[38px] max-md:px-5 bg-custom-grey mx-10 max-w-[1200px] px-10 mt-40 max-md:mt-24 max-md:mb-24 mb-36">
        <div className="text-white text-3xl text-center font-semibold self-center max-w-[981px] mt-12 max-md:max-w-full max-md:mt-10">
          Oracle Algo Features
          Advanced
          Technology
        </div>
        <div className="self-center flex w-full max-w-[1142px] justify-between gap-5 mt-10 items-start max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
          <div onClick={() => setState({ heading: data[1].heading, body: data[1].body, image: data[1].image, active: 1 })} className={(state.active == 1 ? "text-neutral-800 bg-stone-50" : "text-neutral-400 bg-transparent border-2 border-solid border-white") + " butt cursor-pointer text-center text-lg max-md:text-base font-extrabold grow justify-center items-stretch mt-1 w-1/3 py-3 rounded-[30px] max-md:px-5"}>
            Advanced Charting
          </div>
          <div onClick={() => setState({ heading: data[2].heading, body: data[2].body, image: data[2].image, active: 2 })} className={(state.active == 2 ? "text-neutral-800 bg-stone-50" : "text-neutral-400 bg-transparent border-2 border-solid border-white") + " butt cursor-pointer text-center text-lg max-md:text-base font-extrabold grow justify-center items-stretch mt-1 w-1/3 py-3 rounded-[30px] max-md:px-5"}>
            Advanced Oscillator
          </div>
          <div onClick={() => setState({ heading: data[3].heading, body: data[3].body, image: data[3].image, active: 3 })} className={(state.active == 3 ? "text-neutral-800 bg-stone-50" : "text-neutral-400 bg-transparent border-2 border-solid border-white") + " butt cursor-pointer text-center text-lg max-md:text-base font-extrabold grow justify-center items-stretch mt-1 w-1/3 py-3 rounded-[30px] max-md:px-5"}>
            Advanced Trend
          </div>
        </div>
        <div className="duration-1000  mx-3 mt-20 mb-8 max-md:max-w-full max-md:my-6">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[52%] max-md:w-full max-md:ml-0">
              <div className="flex flex-col items-stretch mt-7 max-md:max-w-full max-md:mt-10">
                <div className="text-white text-3xl font-bold max-md:max-w-full">
                  {state.heading}
                </div>
                <div className="text-zinc-300 text-xl mt-10 max-md:max-w-full max-md:mt-10">
                  {state.body}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
              <img
                loading="lazy"
                srcSet={state.image}
                className="aspect-[2.13] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-10"
              />
            </div>
          </div>
        </div>
      </div>
      </motion.div>
  );
}

export default Oracle1;