import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.css";

const settings = {
    infinite: true,
    speed: 3000,
    arrows: false,
    draggable: true,
    autoplay: true,
    pauseOnHover: true,
    variableWidth: true,
    accessibility: false,
    response: true,
    centerMode: true
};

const data = {
    set1: {
        row1: {
            1: {
                head: "“If you want to learn how to safely profit from trading then this is the place to be.”",
                body: "Unity Academy is a one stop shop that has everything you need to know about trading regardless of whether you’re brand new or have years of experience. What I love most about the unity education and community is that it is backed by facts. If you want to learn how to safely profit from trading then this is the place to be.",
                pfp: "images/r-1.png",
                name: "mdtlion"
            },

            2: {
                head: "“Truly one of the best Crypto groups I have been in.”",
                body: "Truely one of the best Crypto groups I have been in. The team is very genuine and are focused on helping people not only achieve financial goals, but help improve themselves.",
                pfp: "images/r-2.png",
                name: "dangplanter12cc"
            },

            3: {
                head: "“Unity academy is the best trading group. I've been lucky enough to be a part of it.”",
                body: "One of the best part of this group is not only the high success rate but also the daily high quality live trading lessons they offer. The best part? They're absolutely free! They also have a vibrant and active community that makes this group truly special. You can count on the community to provide meaningful input.",
                pfp: "images/r-3.png",
                name: "derek1#8661"
            },

            4: {
                head: "“There is no better community in the world than Unity.”",
                body: "There is absolutely no better community in the world than Unity. This group is full of complete pros, The value they provide, the lessons they teach, and all for free, is completely insane. I am still learning and soon gonna start their trading course, super excited and recommend it 100% to anyone, who cares about finances.",
                pfp: "images/r-4.png",
                name: "benas."
            },


            5: {
                head: "“I’ve quit all my other discords. Unity Academy is the best one, no doubt.”",
                body: "I’ve quit all my others Discord. Best one, no doubt. Quality time with quality peoples, I have learn a lot here, and course is very complete and beginner friendly. Peoples in Discord are kind and friendly too!",
                pfp: "images/r-5.png",
                name: "maxouhell"
            },

            6: {
                head: "“Unity Academy provides a priceless experience.”",
                body: "Continue to be impressed with the level of education, resources and teaching Unity Academy provides, priceless experience. The amount of information and resources are beyond any expectations.",
                pfp: "images/r-6.png",
                name: "stee001"
            },

            7: {
                head: "“Nothing compares to Unity Academy’s Trading Mastery Course.”",
                body: "Nothing compares to Unity Academy's Trading Mastery Course & passion in teaching people how to trade by providing valuable information and bringing in great professionals with their respective strategies, Highly recommended, all of them are really nice too especially Caleb.",
                pfp: "images/r-7.png",
                name: "_8di"
            },
            8: {
                head: "“This course has everything from A-Z for becoming a profitable trader. Truly amazing how valuable this information is provided by Unity.”",
                body: " ",
                pfp: "images/r2-1.png",
                name: "user4e111204037"
            },

            9: {
                head: "“Thanks to Unity Academy staff for this amazing course!”",
                body: "A big thanks to unity academy staff for this amazing course! I already have alot of experience, but I still went through the course again. It just turns the complicated world of crypto into simple explanations , its so well organized and the explanation/ sessions are perfect ! Its just amazing opportunity for every trader to master his trading journey",
                pfp: "images/r2-2.png",
                name: "hedo0o"
            },

            10: {
                head: "“Amazing course!”",
                body: "Amazing course! I am a seasoned trader, and I appreciated the concise and clear lessons. There were some areas, like SMC, where I got a better understanding. Plus, it's free!",
                pfp: "images/r2-3.png",
                name: "ellionacci"
            },

            11: {
                head: "“I feel like my knowledge has 10xed since starting this course & I am only 2/3 done with it.”",
                body: "I was super overwhelmed when I first came across trading, Then when I got a chance to be study the Unity Academy course, I jumped to it. Breaking down everything you need to know into short chapters with visuals as well. I feel like my knowledge has 10Xed since starting the course and I am only 2/3 done with it.",
                pfp: "images/r2-4.png",
                name: "durbanvilledon"
            },

            12: {
                head: "“Honestly, this is a solid 5 stars course.”",
                body: "This thing covers it all - starting from the basics of crypto to advanced trading strategies. The way they break down each individual concept and walk you through with examples, it's like a lightbulb moment for your trading skills. Do yourself a favor and check this course out - genuinely incredible.",
                pfp: "images/r2-5.png",
                name: "imark8774"
            },

            13: {
                head: "“Nothing compares to Unity Academy’s Trading Mastery Course.”",
                body: "Nothing compares to Unity Academy's Trading Mastery Course & passion in teaching people how to trade by providing valuable information and bringing in great professionals with their respective strategies, Highly recommended, all of them are really nice too especially Caleb.",
                pfp: "images/r2-6.png",
                name: "_8di"
            },

            14: {
                head: "“Kudos to the entire team for curating such a valuable resource!”",
                body: "The course simplifies intricate concepts, catering to anyone interested in trading. This course was a revelation, helping me comprehend the governing factors of this volatile market. The mastery section’s approach to Elliott Waves and Fibonacci is praiseworthy, breaking down complexities that often drive people away.",
                pfp: "images/r2-7.png",
                name: "blazed_eyes"
            },



            16: {
                head: "“I’ve gained valuable knowledge from their trading mastery course.”",
                body: "Dynamic trading community that offers education, insightful analysis, and trading opportunities. As a long-time member, I've gained valuable knowledge from their comprehensive resources. Unity Academy's unique blend of learning, community, and actionable insights sets it apart in the trading world.",
                pfp: "images/r2-9.png",
                name: "toparchaeologyac6d"
            },

            17: {
                head: "“The mastery course is a valuable asset to anyone who wants to master trading.”",
                body: "I have read through the Mastery course here and there and I got to say it is really organized and it looked easy to get into. It gives me pretty clear inside on what to focus on and what not. I would recommend this to anyone that is willing to learn about crypto.",
                pfp: "images/r2-10.png",
                name: "mytholoon"
            },




        },

        row2: {
            1: {
                head: "“Unity Academy is second to none trading community.”",
                body: "Unity Academy is a second to none trading community. Crypto, forex, stocks, it's all there lead by individuals with years of experience and high success rates (many over 80%). If you're looking for a community to help you learn trading, this is a supportive community with great results. Can't ask for better.",
                pfp: "images/r-8.png",
                name: "maryl26"
            },

            2: {
                head: "“Many ways of increasing your knowledge. Chats, streams, courses, videos, signals & more.”",
                body: "Clear, helpful instructions for all levels from complete beginner to pretty good. Many ways of increasing your knowledge. Chat, live streams, courses, videos, 1 on 1 lessons. Genuine crew. No idiots tolerated. All good.",
                pfp: "images/r-9.png",
                name: "saffsarts"
            },

            3: {
                head: "“This group taught me how to make money by myself.”",
                body: "This group helped many people, including me, learn how to make money by ourselves. The founder himself encourages everyone to be financially independent by investing in themselves through learning. All of the staff are knowledgeable in what they are teaching making everyone comfortable to ask questions.",
                pfp: "images/r-10.png",
                name: "dyanloyd"
            },

            4: {
                head: "“This course is top notch, so glad I didn’t miss out on this.”",
                body: "Unity Academy is a total game-changer in the crypto trading scene! With an impressive 80%+ success rate, these folks seriously know their stuff. Lively community where pros and beginners come together for those sweet profitable calls. Missing out on Unity Academy? Not a chance!",
                pfp: "images/r-11.png",
                name: "Johnny Jr."
            },

            5: {
                head: "““Awesome community - I’ve seen traders grow from newbies to active traders.”",
                body: "Awesome community - I've seen other traders grow from newbies to active traders.The Unity Academy course can help fast track your path to becoming a consistent and profitable trader. I fully recommend it to anyone who's willing to put in the time and the effort. Kudos to Caleb and the team!",
                pfp: "images/r-12.png",
                name: "rekt4effekt"
            },

            6: {
                head: "“I’ve gained valuable knowledge from their trading mastery course.”",
                body: "Dynamic trading community that offers education, insightful analysis, and trading opportunities. As a long-time member, I've gained valuable knowledge from their comprehensive resources. Unity Academy's unique blend of learning, community, and actionable insights sets it apart in the trading world.",
                pfp: "images/r-13.png",
                name: "toparchaeologyac6d"
            },

            7: {
                head: " “My knowledge has 10xed since starting this course & I am only 2/3 done with it.”",
                body: "I was super overwhelmed when I first came across trading, Then when I got a chance to be study the Unity Academy course, I jumped to it. Breaking down everything you need to know into short chapters with visuals as well. I feel like my knowledge has 10Xed since starting the course and I am only 2/3 done with it. ",
                pfp: "images/r-14.png",
                name: "durbanvilledon"
            },
            8: {
                head: "“This is the best crypto course so far I've started it and I'm gaining alot hope to gain more soon.”",
                body: " ",
                pfp: "images/r2-13.png",
                name: "degendav3"
            },

            9: {
                head: "“I’ve gained valuable knowledge from their trading mastery course.”",
                body: "Dynamic trading community that offers education, insightful analysis, and trading opportunities. As a long-time member, I've gained valuable knowledge from their comprehensive resources. Unity Academy's unique blend of learning, community, and actionable insights sets it apart in the trading world.",
                pfp: "images/r2-14.png",
                name: "toparchaeologyac6d"
            },

            10: {
                head: "“Strongly recommend this excellent resource!”",
                body: "I must say that it's an excellent resource for anyone trying to expand their knowledge. The course covers a wide range of subjects. Even as a  experienced trader, I discovered useful insights that broadened my understanding. Complex subjects are simplified with well-produced videos and extensive textual information. Strongly recommended!!!",
                pfp: "images/r2-15.png",
                name: "easybinge9dfe"
            },

            11: {
                head: "“This course is nothing short of exceptional.”",
                body: "I must say that it has been an absolute game-changer for my financial journey. This course is nothing short of exceptional, and I would highly recommend it to anyone looking to dive into the world of trading.",
                pfp: "images/r2-16.png",
                name: "captain_a1"
            },

            12: {
                head: "“I recommend this to everyone. The instructors are experienced who explain concepts perfectly.”",
                body: "It is comprehensive and well-structured course that covers all the essential topics you need to know to start trading cryptocurrencies. The course covers a wide range of topics, including: Technical analysis fundamental analysis, risk management & trading psychology. The course is well-structured and easy to follow.",
                pfp: "images/r2-17.png",
                name: "dr90"
            },

            13: {
                head: "“I can guarantee that even complete beginners can easily learn from this.”",
                body: "It is comprehensive and well-structured course that covers all the essential topics you need to know to start trading cryptocurrencies. The course covers a wide range of topics, including: Technical analysis fundamental analysis, risk management & trading psychology. The course is well-structured and easy to follow.",
                pfp: "images/r2-18.png",
                name: ".exel."
            },

            14: {
                head: "“Unity Academy has become my favorite place to learn more about trading.”",
                body: "This crypto course is really helpful for those trying to start learning crypto trading. It's very well laid out and easy to understand. I can guarantee that even complete beginners can easily learn something from this and after reading you will find yourself better at trade execution and risk management (which I lack before).",
                pfp: "images/r2-19.png",
                name: "dcrypt777"
            },

            15: {
                head: "“Traps all levels of expertise. Easy to navigate contingent on current skillset.”",
                body: " ",
                pfp: "images/r2-15.png",
                name: "mfiri046"
            },

            16: {
                head: "“Very informative and a very profitable! You would not want to miss being in here!”",
                body: " ",
                pfp: "images/r2-16.png",
                name: "chuchu"
            },

            17: {
                head: "“This course is top notch, so glad I didn’t miss out on this.”",
                body: "This crypto course is really helpful for those trying to start learning crypto trading. It's very well laid out and easy to understand. I can guarantee that even complete beginners can easily learn something from this and after reading you will find yourself better at trade execution and risk management (which I lack before).",
                pfp: "images/r2-17.png",
                name: "Johnny Jr."
            },

            18: {
                head: "“Best course you can ever find out there.”",
                body: "Unity Academy is a total game-changer in the crypto trading scene! With an impressive 80%+ success rate, these folks seriously know their stuff. Lively community where pros and beginners come together for those sweet profitable calls. Missing out on Unity Academy? Not a chance!",
                pfp: "images/r2-18.png",
                name: "ac8d"
            },

            19: {
                head: "“Professional teaching techniques shared.”",
                body: "Best course you can ever find out there... top analysts guiding you from being a novice to a pro like them! This is just legendary",
                pfp: "images/r2-19.png",
                name: "bloodmoon7968"
            }
        },
    },



}

function NoTest(props) {
    return (
        <div className="mt-36 mb-36">
            <div className="text-white text-center text-4xl mb-[3rem] font-bold max-md:text-2xl px-2">
                Ready for AcademyPro to help you achieve your trading goals?
            </div>

            <Slider {...settings}>
                {Object.keys((props.set1 == "true" ? data.set1.row1 : data.set2.row1)).map((detailKey) => {
                    return (
                        <div className="newS mx-2 small1 max-w-[90vw]">
                            <div style={{ background: "linear-gradient(98deg, rgba(0, 0, 0, 0.31) 1.09%, rgba(104, 104, 104, 0.29) 100%)" }} className="h-full min-w-[300px] max-w-[500px] border justify-between shadow-lg flex grow flex-col pl-7 pr-5 py-7 rounded-3xl border-solid border-white border-opacity-20 max-md:mt-6 max-md:pl-5">
                                <div className="text-neutral-200 font-extrabold max-md:max-w-full">
                                    {props.set1 == "true" ? data.set1.row1[detailKey].head : data.set1.row1[detailKey].head}
                                </div>
                                <div className="text-neutral-400 text-[13.5px] mt-6 max-md:max-w-full max-md:mt-10">
                                    {props.set1 == "true" ? data.set1.row1[detailKey].body : data.set1.row1[detailKey].body}
                                </div>
                                <div className="flex w-full items-stretch justify-between gap-5 mt-4 max-md:max-w-full max-md:flex-wrap">
                                    <div className="flex items-stretch items-center justify-between gap-2.5">
                                        <img srcSet={props.set1 == "true" ? data.set1.row1[detailKey].pfp : data.set1.row1[detailKey].pfp} className="h-10 w-10 mt-1" />
                                        <div className="self-center ml-1 flex grow basis-[0%] flex-col items-stretch my-auto">
                                            <div className="text-neutral-200 font-semibold whitespace-nowrap">
                                                {props.set1 == "true" ? data.set1.row1[detailKey].name : data.set1.row1[detailKey].name}
                                            </div>
                                            <div className="text-neutral-200">
                                                Verified User
                                            </div>
                                        </div>
                                    </div>
                                    <img src="images/5-stars-orange.png" className="aspect-[4.63] object-contain object-center w-[111px] overflow-hidden self-center shrink-0 max-w-full my-auto" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>

            <br />



            <div className="mt-20 flex justify-center">
                <a href="https://whop.com/unity-academy/" className="butt text-black text-center text-xl font-bold bg-white w-[360px] pl-16 pr-12 py-3 rounded-[30px] ">
                    See More Testimonials
                </a>
            </div>


        </div>
    );
}

export default NoTest;