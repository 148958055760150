import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function OracleAlgoPricing() {
    const { showView } = useContext(ViewContext);
    return (
        <motion.div {...slideUp(showView)}>
            <div id="oracleAlgoPricing" className="scroll-my-36 max-md:scroll-my-28 relative flex flex-col items-center justify-center px-5">
                <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />
                <div className="text-white text-center text-5xl font-semibold self-center max-w-[901px] max-md:max-w-full max-md:text-4xl">
                    Maximize your trading profits
                </div>
                <div className="self-stretch w-full mt-14  max-md:mt-2">
                    <div className="gap-5 flex justify-center max-md:flex-col max-md:items-stretch max-md:gap-0">

                       

                        <div className="flex flex-col ml-5 max-md:w-full max-md:ml-0">
                            <div style={{ background: "linear-gradient(98deg, rgba(0, 0, 0, 0.57) 1.09%, rgba(32, 32, 32, 0.42) 100%)" }} className="border flex w-full items-center grow flex-col mx-auto px-12 py-12 rounded-3xl border-solid border-white border-opacity-20 max-md:mt-7 max-md:px-5">
                                <div className="text-white text-center text-2xl font-medium self-center w-[257px]">
                                    Oracle Algo Indicator
                                </div>
                               
                                <div className="text-stone-300 text-center text-5xl font-medium self-center whitespace-nowrap mt-10 max-md:text-4xl">
                                FREE

                                </div>
                                <div className="text-white text-opacity-80 text-center text-xl font-medium self-stretch mt-6">
                                    <span className="font-black">Save 100%</span>
                                    <span className="font-medium"></span>
                                    <span className="font-semibold"></span>
                                </div>
                                <div>
                                    <div className="self-stretch flex items-stretch justify-between gap-3 mb-4 mt-14 max-md:mt-10">
                                        <img srcSet="images/check-mark.png" className="w-5 h-5" />
                                        <div className="text-white  text-base font-medium self-center grow whitespace-nowrap my-auto">
                                        OracleAlgo Indicators
                                        </div>
                                    </div>
                                    <hr className="w-[90%] mx-auto h-[2px] bg-gray-100 border-0 rounded"></hr>
                                    <div className="self-stretch flex items-stretch justify-between gap-3 mt-4 mb-4">
                                        <img srcSet="images/check-mark.png" className="w-5 h-5" />
                                        <div className="text-white text-base font-medium self-center grow whitespace-nowrap my-auto">
                                        15+ Confluences
                                        </div>
                                    </div>
                                    <hr className="w-[90%] mx-auto h-[2px] bg-gray-100 border-0 rounded"></hr>
                                    <div className="self-stretch flex items-stretch justify-between gap-3 mt-4 mb-4">
                                        <img srcSet="images/check-mark.png" className="w-5 h-5" />
                                        <div className="text-white text-base font-medium self-center grow whitespace-nowrap my-auto">
                                        Multiple Timeframes
                                        </div>
                                    </div>
                                    <hr className="w-[90%] mx-auto h-[2px] bg-gray-100 border-0 rounded"></hr>
                                    <div className="self-stretch flex items-stretch justify-between gap-3 mt-4">
                                        <img srcSet="images/check-mark.png" className="w-5 h-5" />
                                        <div className="text-white text-base font-medium self-center grow whitespace-nowrap my-auto">
                                        Full Customization
                                        </div>
                                    </div>
                                    <div className='mt-[55px] max-md:mt-10 flex justify-center'>
                                        <a className="butt text-black cursor-pointer mx-auto text-center text-lg font-medium w-[257px] bg-gray-200 self-stretch justify-center items-center px-16 py-2.5 rounded-[30px] max-md:px-5" href="https://whop.com/unity-academy/">
                                       Get Access
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div style={{ background: "linear-gradient(90deg, rgba(217, 217, 217, 0.08) 0.02%, rgba(217, 217, 217, 0.08) 99.95%, rgba(217, 217, 217, 0.77) 99.96%)" }} className="text-zinc-100 text-center border self-center justify-center items-stretch mt-16 px-11 py-3.5 rounded-[29px] border-solid border-white border-opacity-40 max-md:max-w-full max-md:text-sm max-md:mt-10 max-md:px-5">
                    <span className="font-bold">Price Lock Guarantee. </span>
                    <span className="">
                        Unless you change payment plans you will pay this price for life,{" "}
                    </span>
                </div>
                <div className="text-white text-center text-4xl max-md:text-xl font-bold self-center whitespace-nowrap mt-16 max-md:mt-10">
                    Safe and Secure Checkout
                </div>
                <div className="max-md:scale-75 self-center flex items-start justify-between gap-8 mt-9 max-md:max-w-full max-md:flex-wrap max-md:justify-center">
                    <img srcSet="images/p-1.png" className="aspect-[2.03] object-contain object-center w-[140px] overflow-hidden self-stretch shrink-0 max-w-full" />
                    <img srcSet="images/p-2.png" className="aspect-[2.38] object-contain object-center w-[95px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-3.png" className="aspect-[1.68] object-contain object-center w-[67px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-4.png" className="aspect-[3.57] object-contain object-center w-[143px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-5.png" className="aspect-[3.4] object-contain object-center w-[136px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                </div>
            </div>
            </motion.div>
            );
}

            export default OracleAlgoPricing;