import React, { useRef, useState } from "react";
import Nav from "../components/Navbar.tsx"
import "../styles/globals.css";
import data from "../data/courses.js";

import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
function Dashboard() {
    const { showView } = useContext(ViewContext);
    const [state, setState] = useState({
        showAllCourses: true,
        set: data[1]
    });

    const topRef = useRef(null);
    const scroll = () => {
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div style={{ background: "linear-gradient(112deg, rgba(0, 0, 0, 0.00) 0%, #363636 105.71%)" }} className="flex flex-col text-white px-1 h-fit pb-10">

            <Nav />
            <div className="w-full">
                <p className="title"></p>
                <motion.div {...slideUp(showView)}>
                    <div className=" here relative flex flex-col items-center mx-6 max-w-[95%] ml-[2.35%] max-md:max-w-[100%] max-md:ml-[0%]%">
                        <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />
                        <div ref={topRef} className="text-white scroll-my-28 max-md:scroll-my-20 text-center text-5xl font-medium max-md:text-5xl">
                        Academy Pro Educational Library
                        </div>

                        <div className={state.showAllCourses == true ? "inline-block animate-fade" : "hidden"}>
                            <div className="flex items-stretch w-full mt-8 max-md:w-[100%] max-md:ml-[2%] max-md:flex-col">
                                <div className="flex flex-row items-center nahh border bg-custom-grey shadow-lg gap-fill items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5 max-md:flex-col max-md:items-center">
                                    <div className="flex items-center w-full justify-center gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-5">
                                    {/* <div className="butt text-black cursor-pointer text-center text-lg font-bold bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                            Trading Videos
                                        </div> */}
                                        <a className="butt text-black cursor-pointer text-center text-lg font-bold  bg-gray-200 grow justify-center items-center px-10 py-2 rounded-[30px] max-md:px-5" href="https://trading-dictionary.vercel.app/">
                                            <div >
                                                Trading Dictionary
                                            </div>
                                        </a>
                                        <a className="butt text-black cursor-pointer text-center text-lg font-bold  bg-gray-200 grow justify-center items-center px-10 py-2 rounded-[30px] max-md:px-5" href="https://trading-resources.vercel.app/">
                                            <div>
                                                Trading Resources
                                            </div>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                            <motion.div {...slideUp(showView)}>
                                <div className="self-stretch w-full mt-8 max-md:mt-6">

                                    <div className="gap-5 flex max-md:flex-col max-md:w-[100%] max-md:gap-0">

                                        <div className="flex flex-col items-stretch w-6/12 max-md:w-[100%] max-md:ml-[2%]  ">
                                            <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                                <div className="text-zinc-300 sh text-3xl font-medium mt-3 max-md:max-w-full">
                                                    Trading Introduction
                                                </div>
                                                <div className="flex items-stretch justify-between gap-5 mt-5 self-start">
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                        Beginner
                                                    </div>
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                        24 Lessons
                                                    </div>
                                                </div>
                                                <div className="text-white remove text-lg mt-10 max-md:max-w-full">
                                                    Understand all of the key fundamental foundations of the trading
                                                    basics + much more with our Trading Introduction Course.
                                                </div>
                                                <div className="flex flex-row gap-3   items-center justify-start mt-5">
                                                    
                                                    <a onClick={() => { setState({ showAllCourses: true, set: data[1] }); scroll() }} href={"/courses/" + (state.set.heading.split(" ").join("-").toLocaleLowerCase())}>
                                                        <div className="mt-4 butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                            Start Course
                                                        </div>
                                                    </a> <div onClick={() => { setState({ showAllCourses: false, set: data[1] }); scroll() }} className="flex max-w-full max-md:mr-5 ml-5 items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                                        <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                                        <div className="text-white max-md:hidden cursor-pointer text-xl font-medium my-auto mr-4">
                                                            Show Lessons
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="flex flex-col items-stretch w-6/12 max-md:w-[100%] max-md:ml-[2%] ">
                                            <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                                <div className="text-zinc-300 sh text-3xl font-medium mt-3 max-md:max-w-full">
                                                    Start Trading
                                                </div>
                                                <div className="flex items-stretch justify-between gap-5 mt-5 self-start">
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                        Intermediate
                                                    </div>
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                        28 Lessons
                                                    </div>
                                                </div>
                                                <div className="text-white remove text-lg mt-10 max-md:max-w-full">
                                                    Receive insights on chart analysis, trading plans, execution,
                                                    risk management + much more with our Start Trading Course.
                                                </div>
                                                <div className="flex flex-row gap-3   items-center justify-start mt-5">
                                                   
                                                    <a onClick={() => { setState({ showAllCourses: true, set: data[2] }); scroll() }} href={"/courses/" + (state.set.heading.split(" ").join("-").toLocaleLowerCase())}>
                                                        <div className="mt-4 butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                            Start Course
                                                        </div>
                                                    </a>
                                                    <div onClick={() => { setState({ showAllCourses: false, set: data[2] }); scroll() }} className="flex max-w-full ml-5 items-stretch max-md:mr-5 justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                                        <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                                        <div className="text-white max-md:hidden cursor-pointer text-xl font-medium my-auto mr-4">
                                                            Show Lessons
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </motion.div>
                            <motion.div {...slideUp(showView)}>
                                <div className="self-stretch w-full mt-8 max-md:mt-0 max-md:max-w-full">
                                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                        <div className="flex flex-col items-stretch w-6/12 max-md:w-[100%] max-md:ml-[2%]">
                                            <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                                <div className="text-zinc-300 sh text-3xl font-medium mt-3 max-md:max-w-full">
                                                    Master Trading
                                                </div>
                                                <div className="flex items-stretch justify-between gap-5 mt-4 self-start">
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                        Advanced
                                                    </div>
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                        28 Lessons
                                                    </div>
                                                </div>
                                                <div className="text-white remove text-lg mt-10 max-md:max-w-full">
                                                    Unlock access to high win rate strategies, theory concepts, key
                                                    trading edges, psychological advantages + much more with our
                                                    Master Trading Course.
                                                </div>
                                                <div className="flex flex-row gap-3   items-center justify-start mt-5">
                                                   
                                                    <a onClick={() => { setState({ showAllCourses: true, set: data[3] }); scroll() }} href={"/courses/" + (state.set.heading.split(" ").join("-").toLocaleLowerCase())}>
                                                        <div className="mt-4 butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                            Start Course
                                                        </div>
                                                    </a>
                                                    <div onClick={() => { setState({ showAllCourses: false, set: data[3] }); scroll() }} className="flex max-md:mr-5 ml-5 max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                                        <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                                        <div className="text-white max-md:hidden cursor-pointer text-xl font-medium my-auto mr-4">
                                                            Show Lessons
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="flex flex-col items-stretch w-6/12 max-md:w-[100%] max-md:ml-[2%]">
                                            <div className="border bg-custom-grey shadow-lg flex grow flex-col items-stretch w-full px-10 py-8 rounded-2xl border-solid border-white border-opacity-20 max-md:max-w-full max-md:mt-8 max-md:px-5">
                                                <div className="text-zinc-300 sh text-3xl font-medium mt-3 max-md:max-w-full">
                                                    Self Improvement
                                                </div>
                                                <div className="flex items-stretch justify-between gap-5 mt-4 self-start">
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                        Personal
                                                    </div>
                                                    <div className="text-stone-300 text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-12 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-70 max-md:px-5">
                                                    50 Lessons
                                                    </div>
                                                </div>
                                                <div className="text-white remove text-lg mt-10 max-md:max-w-full">
                                                    Unlock access to self mastery mind conditioning, stoicism, self
                                                    control, fitness training + much more with our Self Improvement Course
                                                </div>
                                                <div className="flex flex-row gap-3   items-center justify-start mt-5">
                                                   
                                                    <a onClick={() => { setState({ showAllCourses: true, set: data[4] }); scroll() }} href={"/courses/" + (state.set.heading.split(" ").join("-").toLocaleLowerCase())}>
                                                        <div className="mt-4 butt text-black cursor-pointer text-center text-xl font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                                            Start Course
                                                        </div>
                                                    </a>
                                                    <div onClick={() => { setState({ showAllCourses: false, set: data[4] }); scroll() }} className="flex max-w-full ml-5 max-md:mr-5 items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                                        <img className='cursor-pointer w-7 h-7 my-auto' src="images/plus.png" />
                                                        <div className="text-white max-md:hidden cursor-pointer text-xl font-medium my-auto mr-4">
                                                            Show Lessons
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                        </div>


                        <div style={{ background: "linear-gradient(180deg, rgba(21, 21, 21, 0.73) 0%, rgba(24, 24, 24, 0.71) 100%)" }} className={(state.showAllCourses === true ? "hidden" : "flex flex-col animate-fade") + " mx-8 max-md:mx-0 justify-between w-full mt-10 border border-solid border-2 border-white rounded-[30px] py-8 px-12 max-md:px-4"}>
                            <div>
                                <div className="text-3xl text-white max-md:text-center">
                                    {state.set.heading}
                                </div>
                                <div className="flex items-stretch gap-5 mt-8 self-start">
                                    {state.set.highlights.map((txt, i) => {
                                        return (
                                            <div className="text-stone-300 max-w-[220px] text-center text-base font-medium bg-zinc-300 bg-opacity-0 grow justify-center items-stretch px-14 py-1.5 rounded-2xl border-2 border-solid border-white border-opacity-80 max-md:px-5">
                                                {txt}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="text-white text-lg mt-10 max-md:max-w-full max-w-[600px]">
                                    {state.set.body}
                                </div>
                                <div className="flex w-[452px] max-w-full items-stretch justify-between gap-3.5 mt-7 self-start max-md:flex-wrap max-md:justify-center">
                                    <img onClick={() => { setState({ showAllCourses: true, set: data[1] }); scroll() }} className='w-7 h-7 cursor-pointer my-auto' src="images/hide.png" />
                                    <div onClick={() => { setState({ showAllCourses: true, set: data[1] }); scroll() }} className="text-white cursor-pointer text-lg font-medium my-auto mr-2">
                                        Hide Lessons
                                    </div>
                                    <a href={"/courses/" + (state.set.heading.split(" ").join("-").toLocaleLowerCase())} className="butt text-black cursor-pointer text-center text-lg font-medium w-[257px] bg-gray-200 grow justify-center items-center px-16 py-2 rounded-[30px] max-md:px-5">
                                        Start Course
                                    </a>
                                </div>
                            </div>
                            <div className="text-white grid max-md:mx-auto grid-flow-row-dense grid-cols-4 max-md:grid-cols-2 mt-10">
                                {state.set.lessons.map((answer, i) => {
                                    return (
                                        <div className="mt-3 mx-2">
                                            {i + 1}.{" " + answer}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </motion.div>

            </div></div>
    );
}

export default Dashboard;