import * as React from "react";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import Landing from "../components/Landing.tsx";
import OracleAlgo from "../components/OracleAlgo.tsx";
import Footer from '../components/Footer.tsx'
import TrustedBy from "../components/TrustedBy.tsx";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
import Onboarding from "../components/Onboarding.tsx";
import UnityAcademy from "../components/UnityAcademy.tsx";
import JoinCommunity from "../components/JoinCommunity.tsx";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.tsx";
import OracleAlgoPricing from "../components/OracleAlgoPricing.tsx";
import TradingCoursePricing from "../components/TradingCoursePricing.tsx";
import OraclePerks from "../components/OraclePerks.tsx";
import JoinUnity from "../components/JoinUnity.tsx";
import Pricing from "../components/Profits.tsx";
import TradingMastery1 from "../components/TradingMastery1.tsx";
import TradingMastery2 from "../components/TradingMastery2.tsx";
import Oracle1 from "../components/Oracle1.tsx";
import { ViewContext } from "../constants/context.ts";
import { slideDown } from "../constants/framer.config.ts";
import Oracle2 from "../components/Oracle2.tsx";
import Oracle3 from "../components/Oracle3.tsx";
import Oracle4 from "../components/Oracle4.tsx";
import { motion } from "framer-motion";
import OracleTrustedBy from "../components/OracleTurstedBy.tsx";
import Nav from "../components/Navbar.tsx"

function Oracle() {
    const { showView } = useContext(ViewContext);
    return (
        <div className="h-screen">

            
            {/* Maintance snippet */}
            {/* <div className="flex flex-col items-center justify-center h-screen" style={{
                backgroundColor: '#0d0d0d'
            }}>
                <img src="https://cdn.discordapp.com/attachments/874259441384574976/1205764766375870534/7971.jpg?ex=65ec03f2&is=65d98ef2&hm=6f72e71b25ee22436c25d318391fa867293d241f682fcc577613c7f5986b6041&" className="right tweak h-screen w-screen" alt="" />
                <img src="https://www.unityacademy.io/images/logo.webp" className="z-10 w-[300px]" alt="" />
                <p className="more text-[1.35rem] font-semi opacity-80 mt-[1rem] font-nunito text-white text-center max-md:m-8">The Website and the Courses are down for regular
                    maintenance, Please check in after a while.</p>
            </div> */}
<div className="ml-[0.5rem]">
                <Nav />
            </div>
            <div className="flex flex-col items-center justify-center" >

               
                <Oracle1 />
                <Oracle2 />
                <Oracle3 />
                <Oracle4 />
                <OraclePerks />
            </div>
            <OracleTrustedBy set1="true" />
            <div className="flex flex-col items-center justify-center">
                <div className="mt-[-2rem]"></div>
                <OracleAlgoPricing />

                <div className="mt-[8rem]"></div>
               

                <FrequentlyAskedQuestions />
                <JoinCommunity />
            </div>
            <Footer />

        </div>
    );
}

export default Oracle;