/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Nav from './Navbar.tsx';
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import TextTransition, { presets } from 'react-text-transition';
function Landing() {
  const { showView } = useContext(ViewContext);
  const [index, setIndex] = React.useState(0);
  const TEXTS = ['Crypto', 'Futures', 'Spot'];
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1500,
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div className="px-2 pb-5 min-h-[100vh] w-full flex flex-col items-start justify-between" style={{ background: "linear-gradient(112deg, rgba(0, 0, 0, 0.00) 0%, #363636 100%)" }}>
      <Nav />
      {/* dont remove empty div */}
      <div className='h-[70px]'></div>
      <motion.div className='w-full flex items-center justify-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      ><div className='flex items-center justify-center w-full ml-2 max-w-[1300px] mt-2.5'>
          <div className='flex flex-col items-center justify-center w-[100%] mt-[10rem] max-md:mt-0'>
            <div className="remove inline-flex text-white text-[2.6rem] max-w-[1000px] font-semibold leading-[56px]  mt-[-8rem] max-md:mt-28 w-[700px] self-start max-md:max-w-full max-md:mt-10">
              <p> We teach how to be successful trading <TextTransition className='mt-[-3.45rem] ml-[9.6rem]' springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>     </p>

            </div>

            <div className="mx-auto appear text-center text-white text-3xl max-md:mt-20 max-w-[1000px] font-bold leading-[40px] self-start max-md:max-w-full">
              <p> We teach you how to

                trade <TextTransition className='text-center w-full items-center justify-center mt-1.4' springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>     </p>
            </div>

            <div className="remove mt-[2.4rem] text-stone-300 text-[1.3rem] max-w-[450px] font-medium leading-10 max-w-[697px]  mt-10 mb-3 self-start max-md:max-w-full max-md:leading-8 max-md:mt-6 flex">
              <div className="wow">
                <p className='text-sm'>Features</p>
                <p className='font-bold'>OracleAlgo Indicators  </p>
              </div> <span className='border-stone-300 ml-10 opacity-30 mr-10 rounded-[100px] border-[1.5px]  w-[1px] mr-[2.5rem] ml-[2.5rem]'></span>
              <div className="wow">
                <p className='text-sm'>Access to</p>
                <p className='font-bold'>UnityAcademy Pro</p>
              </div>
            </div>

            <div className="appear text-stone-300 text-center text-[1.3rem] max-w-[450px] font-medium leading-10 max-w-[697px] mx-auto mt-10 self-start max-md:max-w-full max-md:leading-8 max-md:mt-6">

              A Crypto fintech firm democratizing wealth through education, data analytics, and conversational finance in crypto.            <br />
              <div className='h-4'></div>
              Daily Trade Setups & Livestreams
              <br />

            </div>

            <div className="flex gap-5 max-md:justify-center max-md:mx-auto mt-8 self-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 mb-4">
              <a href="https://whop.com/unity-academy">
                <div className="butt bit text-neutral-800 whitespace-nowrap cursor-pointer text-lg font-semibold bg-stone-50 justify-center items-stretch px-9 py-3 rounded-[30px] max-md:px-5 max-md:w-[300px]">
                  Free OracleAlgo Access
                </div>
              </a>

              <a href="https://whop.com/unity-academy/">
                <div className="butt3 butt text-white cursor-pointer text-lg font-semibold text-center grow justify-center items-center flex px-9 py-3 rounded-[29px] border-2 border-solid border-orange-50 max-md:px-5 max-md:w-[300px]">
                  Access AcademyPro
                </div>
              </a>
            </div>

            <a href='https://whop.com/unity-academy/' className="justify-center flex items-start gap-3  max-md:mx-auto mt-8 self-start max-md:max-w-full max-md:flex-wrap max-md:mt-10">
              <div className="text-white text-sm font-bold whitespace-nowrap my-auto">
                Our customers say Excellent
              </div>
              <img srcSet="images/5-stars.png" />
              <div className="text-white text-sm font-bold my-auto">
                4.98 out of 5 based on reviews
              </div>
              <img srcSet="images/review-site-logo.png" />
            </a>

          </div>
          <img className="max-w-[480px] mt-[1rem] remove pt-2 more" src="images/gg.png" alt="" />
        </div>

      </motion.div>
      <div className="w-full opacity-0 ml-12 mr-12 self-center flex ml-0 items-start justify-between gap-5 px-10 max-md:px-2  mt-20 mb-5 flex-wrap max-md:justify-center max-md:mt-10">
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">MarketWatch</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">NBC</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">Business Insider</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">ABC</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">YAHOO FINANCE</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">FOX</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">BLOOMBERG</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">#INVESTING</div>
        <div className="text-zinc-400 whitespace-nowrap text-lg font-bold">TradingReview</div>
      </div>

    </div>
  );
}

export default Landing;