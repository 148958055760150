import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.tsx";
import Affiliate from "./pages/Affiliate.tsx";
import Team from "./pages/team.tsx";
import Course from "./pages/Course.tsx";
import Recap from "./pages/recap.tsx";
import Dashboard from "./pages/dashboard.tsx";
import Pricing from "./pages/pricing.tsx";
import Legal from "./pages/legal.tsx";
import Oracle from "./pages/oracle.tsx";
import OraclePricing from "./pages/oraclePricing.tsx";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home/>} />
          <Route path="affiliate" element={<Affiliate/>} />
          <Route path="team" element={<Team/>} />
          <Route path="dashboard" element={<Dashboard/>} />
          <Route path="recap" element={<Recap/>} />
          <Route path="pricing" element={<Pricing/>} />
          <Route path="legal" element={<Legal/>} />
          <Route path="oracle" element={<Oracle/>} />
          <Route path="OraclePricing" element={<OraclePricing/>} />
        </Route>
        
        <Route path="/courses">
          <Route path="trading-introduction" element={<Course course={1}/>} />
          <Route path="start-trading" element={<Course course={2}/>} />
          <Route path="master-trading" element={<Course course={3}/>} />
          <Route path="self-improvement" element={<Course course={4}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
