import * as React from "react";
import Nav from "../components/Navbar.tsx"
import Footer from "../components/Footer.tsx"
import "./affiliate.css"
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";

import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.tsx";

function Team() {
    const { showView } = useContext(ViewContext);
    return (
        <div className=" from-transparent via-transparent to-gray-700 w-full h-full pb-[5rem]">
            <div className="ahh">
                <Nav />
            </div>

            <div className="flex flex-col items-center justify-center mb-20">
                <div className="head text-[5rem] mt-[10rem]">
                    Meet the Team
                </div>
                <div className="element gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4 ">

                    <div className="cc flex t1 flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Caleb</div>
                            <div className="position">Founder</div>

                            <a href="https://x.com/CalebUnity_" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>

                    <div className="cc neil t3 flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Arshmeister</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Arshmeister" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <div className="cc neil t2 flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Neilarora</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/neilarora16" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>


                </div>
                <div className="element gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4 ">

                    <div className="cc flex t4 flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Sherlock</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Sherlockwhale" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>

                    <div className="cc neil t6 flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">1oo1</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Harish_Dev" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <div className="cc neil t7 flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Pika</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/pikacrypto5" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>


                </div>
                <div className="element gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4 ">

                    <div className="cc flex scient flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Scient</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Crypto_Scient" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>

                    <div className="cc neil seb flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Seb</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://discord.gg/unityacademy" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <div className="cc neil tip flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Tiprolin</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://discord.gg/unityacademy" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>


                </div>
                <div className="element gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4 ">

                    <div className="cc flex gras flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Grasady</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Grasady" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>

                    <div className="cc certa seb flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Certa</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://discord.gg/unityacademy" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <div className="cc waalg tip flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">WaalG</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://discord.gg/unityacademy" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>


                </div>
                <div className="element gap-7 justify-center flex max-[1300px]:flex-wrap max-md:items-stretch max-md:gap-4 ">

                    <div className="cc flex xt flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">XT</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/xt_trades" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>

                    <div className="cc certa wil flex flex-col items-center justify-end gap-3">
                        <div className="flex flex-col gap-3 w-full px-20 duck">
                            <div className="name">Wilsauce</div>
                            <div className="position">Crypto Caller</div>

                            <a href="https://x.com/Wilsauce_trades" className="butt butt3 text-white max-md:mx-auto cursor-pointer text-center text-sm mt-2 font-bold bg-transparent justify-end items-stretch px-10 mb-2 max-md:mt-0 py-2 rounded-[30px]  max-md:px-10">
                                Twitter
                            </a>
                        </div>
                    </div>
                   


                </div>

            </div>
            <Footer />
        </div>

    );
}

export default Team;