import React, { useRef, useState } from "react";
import Nav from "../components/Navbar.tsx"
import "../styles/globals.css";
import Footer from '../components/Footer.tsx'
import data from "../data/courses.js";
import TradingCoursePricing from "../components/TradingCoursePricing.tsx";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
import OracleAlgoPricing from "../components/OracleAlgoPricing.tsx";
import Notest from "../components/NoTest.tsx";
function OraclePricing() {
    const { showView } = useContext(ViewContext);



    return (
        <div style={{ background: "#0D0D0D" }} className="flex flex-col text-white px-1 h-fit">

            <Nav />
            <div className="mt-[10rem]"></div>
            <div className="relative flex flex-col items-center px-5 mb-[10rem]" id='pricing'>
                <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />

                
                <OracleAlgoPricing/>
            </div>
            <Footer />
        </div>

    );
}

export default OraclePricing;