import React, { useRef, useState } from "react";
import Nav from "../components/Navbar.tsx"
import "../styles/globals.css";
import Footer from '../components/Footer.tsx'
import data from "../data/courses.js";
import TradingCoursePricing from "../components/TradingCoursePricing.tsx";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
import Notest from "../components/NoTest.tsx";
function Pricing() {
    const { showView } = useContext(ViewContext);



    return (
        <div style={{ background: "#0D0D0D" }} className="flex flex-col text-white px-1 h-fit">

            <Nav />
            <div className="mt-[10rem]"></div>
            <div className="relative flex flex-col items-center px-5" id='pricing'>
                <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />

                <div className="text-white text-center text-4xl font-bold self-stretch w-full max-md:max-w-full max-md:text-4xl">
                    Unity Academy Pro Pricing
                </div>
                <div className=" text-[1.375rem] mt-8 text-center text-gray-300">
                    How much would you pay to save yourself from thousands of trading
                    losses?
                </div>
                <div className=" flex items-center justify-center w-full mt-10 max-md:max-w-full max-md:mt-10">
                    <div className="w-[27.875rem] rounded-[26px] [background:linear-gradient(97.58deg,_rgba(0,_0,_0,_0.57),_rgba(32,_32,_32,_0.42))] box-border flex flex-col items-center justify-center py-[2.625rem] px-[2.188rem] gap-[2.438rem] text-[1.125rem] border-[2px] border-solid border-gray-600">
                        <div className="rounded-[19px] [background:linear-gradient(270deg,_#3d3d3d,_#000)] flex flex-col items-center justify-start py-[0.375rem] px-[3.063rem] border-[1px] border-solid border-gainsboro-200">
                            <div className="self-stretch relative">
                                <span className="font-medium">{`Price `}</span>
                                <span className="text-mediumspringgreen">
                                    <b>Locked</b>

                                </span>
                            </div>
                        </div>
                        <div className="self-stretch relative text-center text-[3.313rem] font-body-3 font-sans ">
                            <span>
                                <b className="leading-[118.6%]">$49.99</b>
                            </span>
                            <span className="leading-[133.67%] text-[2rem] text-gray-400 font-nunito">
                                <span>
                                    <b className="font-nunito">{` `}</b>
                                </span>
                                <span className="font-medium">/ {` `} Month</span>
                            </span>
                        </div>
                        <div className="fuck butt w-full text-neutral-800 py-3 cursor-pointer text-center font-bold max-md:text-base bg-stone-50 justify-center items-stretch rounded-[30px]">
                            <a href="https://whop.com/unity-academy/">
                                Get Access
                            </a>
                        </div>
                        <div className="self-stretch flex flex-col items-center justify-center gap-[1.25rem_0rem] text-left text-[1rem]">
                            <div className="self-stretch rounded-[1px] flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        {" "}
                                        Exclusive Trade Setups From Our Analysts
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Comprehensive Trading Education
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 h-[0.875rem] flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        {" "}
                                        5-10 Weekly Live Crypto Trading Lessons
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Supportive Community Environment
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Access to Venture Capital Firm
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: "linear-gradient(90deg, rgba(217, 217, 217, 0.08) 0.02%, rgba(217, 217, 217, 0.08) 99.95%, rgba(217, 217, 217, 0.77) 99.96%)" }} className="text-zinc-100 text-center border self-center justify-center max-md:text-sm items-stretch mt-8 px-11 py-3.5 rounded-[29px] border-solid border-white border-opacity-40 max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <span className="font-bold">Price Lock Guarantee. </span>
                    <span className="">
                        Safe and Secure Checkout
                    </span>
                </div>
                <div className="gap-5 mt-[6rem] justify-center items-center flex flex-wrap max-md:gap-6">
                    <div className="text-white text-center text-4xl mb-[3rem] font-semibold self-stretch w-full max-md:max-w-full max-md:text-4xl">
                        Whats Included?
                    </div>
                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="border news shadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">

                            <img src="images/f1.png" alt="" className="mt-[-3.5rem] w-[36px]" />

                            <div className="text-stone-300 text-center text-lg mt-5 font-medium self-stretch  ">
                                Exclusive Trade Setups Offered By Industry specialist analysts
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="shadow-sm news border bg-custom-greyshadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <img src="images/f2.png" alt="" className="mt-[-3.5rem] w-[36px]" />
                            <div className="text-stone-300 text-center mt-5 text-lg font-medium self-stretch  ">
                                Comprehensive Trading Education Through Our 4 Courses
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="shadow-sm news border bg-custom-greyshadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <img src="images/f3.png" alt="" className="mt-[-3.5rem] w-[36px]" />
                            <div className="text-stone-300 mt-6 text-center text-lg font-medium self-stretch  ">
                                24/7 Personalized Mentorship Support and 1/1 Support
                            </div>

                        </div>
                    </div>
                </div>
                <div className="gap-5 mt-[3rem] justify-center items-center flex flex-wrap max-md:gap-6">

                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="border news shadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">

                        <img src="images/f5.png" alt="" className="mt-[-3.5rem] w-[36px]" />

                            <div className="text-stone-300 text-center text-lg mt-6 font-medium self-stretch  ">
                                Weekly Crypto Trading Livestream Lessons
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="shadow-sm news border bg-custom-greyshadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <img src="images/f6.png" alt="" className="mt-[-3.5rem] w-[36px]" />
                            <div className="text-stone-300 text-center mt-6 text-lg font-medium self-stretch  ">
                                Track, Learn & Follow world-class trade setups from our team
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
                        <div className="shadow-sm news border bg-custom-greyshadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                        <img src="images/f4.png" alt="" className="mt-[-3.5rem] w-[36px]" />
                            <div className="text-stone-300 mt-6 text-center text-lg font-medium self-stretch  ">
                                Supportive Family Tight Community filled with advanced traders.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Notest set1="true" />
            <Footer />
        </div>

    );
}

export default Pricing;