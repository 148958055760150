import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function Oracle1() {
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)}>
      <div id="oracleAlgo" className="scroll-my-36 max-md:scroll-my-28 relative mx-4 mt-56 max-md:mt-20 mb-56 max-md:mb-20 items-center flex justify-center">
        <div style={{ background: "rgba(255, 205, 175, 0.99)", filter: "blur(67.1500015258789px)" }} className="absolute w-1/2 h-[364px] top-[-10px] rounded-[662px] opacity-[0.05]" />
        <div className="max-w-[1100px] gap-5 flex max-md:flex-col max-md:items-stretch">
          <div className="flex flex-col items-stretch w-[71%] max-md:w-full max-md:ml-0">
            <div className="fuck flex flex-col items-stretch my-auto px-5 max-md:max-w-full max-md:mt-10">
              <div className="text-white text-3xl max-w-[400px]  font-bold max-md:max-w-full max-md:text-2xl">

                <img src="images/log1.webp" alt="" className="man max-w-[350px] mt-[-0.5rem]" />
              </div>
              <div className="text-white text-2xl max-w-[580px] mt-12 max-md:max-w-full max-md:text-lg max-md:mt-10">
                Our world-class trading indicators transforms complicated analysis
                into simplified actionable insights
              </div>

              <div className="fuck mt-10 butt text-neutral-800 cursor-pointer text-center text-xl max-md:text-base font-extrabold whitespace-nowrap bg-stone-50 justify-center items-stretch px-14 py-3.5 max-md:py-3 rounded-[30px] self-start  max-md:px-10">
                <a  href="/OraclePricing">
                  Access For Free
                </a>
              </div>

              <div className="fuck max-md:scale-75 flex items-stretch justify-between gap-5 mt-16 self-start max-md:mt-10">
                <img className="h-14" srcSet="images/o1.png" />
                <img className="h-14" srcSet="images/o2.png" />
              </div>
            </div>
          </div>
          <img className='max-md:mx-auto w-[15vw] more min-w-[200px] max-w-[400px] max-md:mt-12 aspect-[0.5] object-contain object-center ml-12' srcSet="images/log5.webp" />
        </div>
      </div>
      /</motion.div>
  );
}

export default Oracle1;