import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function Oracle1(){
  const { showView } = useContext(ViewContext);
    return(
      <motion.div {...slideUp(showView)}>
        <div className="relative flex justify-center mx-4">
          <div style={{background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)"}} className="absolute w-1/2 h-[400px] rounded-[1143px] opacity-[0.04]" />
          <div className="rev max-w-[1200px] gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[55%] max-md:w-full more max-md:ml-0">
              <img srcSet="images/lap.webp" className="aspect-[1.51] max-w-[550px] object-contain object-center w-full overflow-hidden grow max-md:max-w-[90%] max-md:mx-auto max-md:mt-10" />
            </div>
            <div className="fuck flex flex-col items-stretch w-[45%] ml-5 max-md:w-full max-md:mt-10 max-md:ml-0">
              <div className="flex flex-col my-auto px-5 items-start max-md:max-w-full max-md:mt-10">
                <div className="text-white text-3xl font-bold self-stretch max-md:max-w-full max-md:text-2xl">
                  The AI Solution for turning confusion into Clarity
                </div>
                <div className="text-white text-xl font-semibold max-md:font-medium max-md:text-lg self-stretch mt-11 max-md:max-w-full max-md:mt-10">
                  Find confluence in every trade with our indicators designed for
                  any market. Works on any device featuring historical and real-time
                  data.
                </div>
                <div className="mt-16 max-md:mt-10 flex max-md:mx-auto">
                  <a className="fuck butt text-neutral-800 cursor-pointer text-center text-lg max-md:text-base font-extrabold whitespace-nowrap bg-stone-50 justify-center items-stretch px-16 py-3.5 max-md:py-3 rounded-[30px]  max-md:px-10" href="/OraclePricing">
                    Get Advanced Charts
                  </a>
                </div>
              </div>
            </div>
        </div>
      </div>
      /</motion.div>
    );
}

export default Oracle1;