import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function OraclePerks() {
    const { showView } = useContext(ViewContext);
    return (
        <div className="relative flex flex-col items-center px-2 justify-center mx-8">
            <div style={{ background: "rgba(255, 255, 255, 0.99)", filter: "blur(67.1500015258789px)" }} className="top-[250px] absolute h-[460px] w-full rounded-[1143px] opacity-[0.03] " />
            <div className="text-white text-center  mb-12 underline-offset-[20px] decoration-2 text-3xl max-w-[1036px] max-md:max-w-full max-md:underline-offset-[0px]">
                Engineered For Any Trader
            </div>
            <div className="self-stretch w-full mx-auto max-w-[1150px] mt-8 max-md:max-w-full max-md:-mt-6">
            <motion.div {...slideUp(showView)}>
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[33%] ml-12 max-md:w-full max-md:ml-0">
                        <div className="fuck flex flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/14.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Multiple Timeframes
                            </div>
                            <div className="text-zinc-300 self-stretch mt-5">
                                OracleAlgo works with all timeframes including 1m, 5m, 30m, 1H,
                                4H, 1D, 1W, etc.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[34%] ml-8 max-md:w-full max-md:ml-0">
                        <div className="fuck flex flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/13.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Multiple Markets
                            </div>
                            <div className="text-zinc-300 self-stretch mt-5">
                                OracleAlgo works with any market with sufficient volume.
                                Including stocks, forex, crypto, indices, and futures.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[33%] ml-8 max-md:w-full max-md:ml-0">
                        <div className="fuck flex grow flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/9.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Risk Management
                            </div>
                            <div className="text-zinc-300 self-stretch mt-5">
                                OracleAlgo places an emphasis on risk management by identifying
                                optimal confluences for entries, take profits and stoplosses.
                            </div>
                        </div>
                    </div>
                </div>
                /</motion.div>
            </div>
            <motion.div {...slideUp(showView)}>
            <div className="self-stretch w-full mx-auto max-w-[1150px] mt-12 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[33%] ml-12 max-md:w-full max-md:ml-0">
                        <div className="fuck flex grow flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/10.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Strategy Sensitivity
                            </div>
                            <div className="text-zinc-300 self-stretch mt-5">
                                You can change the sensitivity of the indicator to be more or
                                less aggressive based on your preferred trading style.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[34%] ml-8 max-md:w-full max-md:ml-0">
                        <div className="fuck flex grow flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/11.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Customization
                            </div>
                            <div className="text-zinc-300 self-stretch mt-6">
                                You have the ability to enable or disable any tool you desire.
                                Just go to the settings of the indicator and pick and choose
                                what you prefer.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[33%] ml-8 max-md:w-full max-md:ml-0">
                        <div className="fuck flex grow flex-col items-start max-md:mt-10">
                            <img className="h-[70px] w-[70px]" src="images/12.png" />
                            <div className="text-white text-xl font-bold self-stretch whitespace-nowrap mt-7">
                                Exclusive Indicators
                            </div>
                            <div className="text-zinc-300 self-stretch mt-6">
                                OracleAlgo includes many exclusive indicators like market
                                structure mapping, breakouts, trends, volume oscillators + so
                                much more.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </motion.div>
        </div>
    );
}

export default OraclePerks;