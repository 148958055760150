import * as React from 'react';
import data from "../data/courses.js"
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function TradingCoursePricing() {
    const { showView } = useContext(ViewContext);
    return (
        <motion.div {...slideUp(showView)}>
            <div className="relative flex flex-col items-center px-5" id='pricing'>
                <div style={{ background: "rgba(255, 247, 242, 0.99)", filter: "blur(134.64999389648438px)" }} className="top-[400px] absolute w-1/2 h-[200px] rounded-[1381px] opacity-[0.05]" />

                <div className="text-white text-center text-4xl font-semibold self-stretch w-full max-md:max-w-full max-md:text-4xl">
                    Maximize your trading profits
                </div>
                <div className="self-stretch w-full mt-10 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex justify-center max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className=" flex items-center justify-center w-full mt-10 max-md:max-w-full max-md:mt-10">
                    <div className="w-[27.875rem] text-white rounded-[26px] [background:linear-gradient(97.58deg,_rgba(0,_0,_0,_0.57),_rgba(32,_32,_32,_0.42))] box-border flex flex-col items-center justify-center py-[2.625rem] px-[2.188rem] gap-[2.438rem] text-[1.125rem] border-[2px] border-solid border-gray-600">
                        <div className="rounded-[19px] [background:linear-gradient(270deg,_#3d3d3d,_#000)] flex flex-col items-center justify-start py-[0.375rem] px-[3.063rem] border-[1px] border-solid border-gainsboro-200">
                            <div className="self-stretch relative">
                                <span className="font-medium ">{`Price `}</span>
                                <span className="text-mediumspringgreen">
                                    <b>Locked</b>

                                </span>
                            </div>
                        </div>
                        <div className="self-stretch relative text-center text-[3.313rem] font-body-3">
                            <span>
                                <b className="leading-[118.6%]">$49.99</b>
                            </span>
                            <span className="leading-[133.67%] text-[2rem] text-gray-400 font-nunito">
                                <span>
                                    <b className="font-nunito">{` `}</b>
                                </span>
                                <span className="font-medium">/Month</span>
                            </span>
                        </div>
                        <div className="fuck butt w-full text-neutral-800 py-3 cursor-pointer text-center font-bold max-md:text-base bg-stone-50 justify-center items-stretch rounded-[30px]">
                            <a href="https://whop.com/unity-academy/">
                                Get Access
                            </a>
                        </div>
                        <div className="self-stretch flex flex-col items-center justify-center gap-[1.25rem_0rem] text-left text-[1rem]">
                            <div className="self-stretch rounded-[1px] flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        {" "}
                                        Exclusive Trade Setups From Our Analysts
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Comprehensive Trading Education
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 h-[0.875rem] flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        {" "}
                                        5-10 Weekly Live Crypto Trading Lessons
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Supportive Community Environment
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-center pt-[0rem] px-[0rem] pb-[0.938rem] gap-[0rem_0.75rem] border-b-[1px] border-solid border-gray-500">
                                <img
                                    className="w-[1.125rem] relative h-[1.125rem]"
                                    alt=""
                                    src="images/Vector.png"
                                />
                                <div className="flex-1 flex flex-col items-center justify-center">
                                    <div className="self-stretch relative font-medium">
                                        Access to Venture Capital Firm
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                    </div>
                </div>
                <div style={{ background: "linear-gradient(90deg, rgba(217, 217, 217, 0.08) 0.02%, rgba(217, 217, 217, 0.08) 99.95%, rgba(217, 217, 217, 0.77) 99.96%)" }} className="text-zinc-100 text-center border self-center justify-center max-md:text-sm items-stretch mt-16 px-11 py-3.5 rounded-[29px] border-solid border-white border-opacity-40 max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <span className="font-bold">Price Lock Guarantee. </span>
                    <span className="">
                        Unless you change payment plans you will pay this price for life,{" "}
                    </span>
                </div>
                <div className="text-white text-center text-4xl max-md:text-xl font-bold whitespace-nowrap mt-9">
                    Safe and Secure Checkout
                </div>
                <div className="max-md:scale-75 self-center flex items-start justify-between gap-8   mt-9 max-md:max-w-full max-md:flex-wrap max-md:justify-center">
                    <img srcSet="images/p-1.png" className="aspect-[2.03] object-contain object-center w-[140px] overflow-hidden self-stretch shrink-0 max-w-full" />
                    <img srcSet="images/p-2.png" className="aspect-[2.38] object-contain object-center w-[95px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-3.png" className="aspect-[1.68] object-contain object-center w-[67px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-4.png" className="aspect-[3.57] object-contain object-center w-[143px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-5.png" className="aspect-[3.4] object-contain object-center w-[136px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                </div>
            </div>
        </motion.div>
    );
}

export default TradingCoursePricing;