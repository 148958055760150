import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.css";

const settings = {
    infinite: true,
    speed: 3000,
    arrows: false,
    draggable: true,
    autoplay: true,
    pauseOnHover: true,
    variableWidth: true,
    accessibility: false,
    response: true,
    centerMode: true
};


const data = {
    set1: {
        row1: {
            1: {
                head: "“Secured a 1:7 RR on my first trade with OracleAlgo.”",
                body: "The OracleAlgo Indicator is allowing me to secure crazy profits. My first trade and it went 1:7 RR. I don’t use indicators but this thing is totally worth it. It fits in perfectly with my trading strategy.",
                pfp: "images/p1.webp",
                name: "Crptojod"
            },

            2: {
                head: "  “OracleAlgo indicator has helped me making thousands of dollars profit trading.”",
                body: " Once i got early access to OracleAlgo, i have utilised it on many trades on both the lower and higher time frame. It is perfect to provide you all the confluences you need to work out your entries and take profits at areas of potential reversals.",
                pfp: "images/p2.webp",
                name: "  HarryP1512"
            },

            3: {
                head: " “OracleAlgo is extremely helpful, it has given me a trading edge.”",
                body: "The indicator that unity has made is extremely helpful. I’m still very early on in my learning and have tried many indicators. This one is not messy and gives me an edge when analysing charts. The confluences provided are second to none.",
                pfp: "images/p3.webp",
                name: " ellionacci"
            },

            4: {
                head: "“This is the best crypto course so far I've started it and I'm gaining alot hope to gain more soon.”",
                body: "      OracleAlgo shows me the best potential entries backed by many different confluences, with Oracle it is much clear to see probable entry points and scanning of charts.",
                pfp: "images/p4.webp",
                name: "      PaperHands"
            },


            5: {
                head: " “I love OracleAlgo, i have made so many profitable trades with it.”",
                body: "Oracle always gives me sniper entries with the best confluences, identifying the market structure, trend and price action with the oscillator!",
                pfp: "images/p5.webp",
                name: "    Shmachs"
            },

            6: {
                head: "                                    “OracleAlgo is the best indicator i have ever used.”",
                body: "                                    It is insanely helpful in identifying market structure, marking levels, spotting trend reversals. It is a really useful tool which has allowed me to improve my trading skills, i can’t imagine trading without it.",
                pfp: "images/p6.webp",
                name: "         liklife"
            },
        },



    },
}
function OracleTrustedBy(props) {
    return (

        <div className="mt-40 max-md:mt-24 mb-56 max-md:mb-36 mx-6">
            <div className="text-white flex flex-row text-center text-4xl w-full items-center gap-3 max-md:flex-col justify-center font-bold max-md:text-4xl px-2">
                <img src="https://media.discordapp.net/attachments/1150421752028090519/1194696774179364935/ORACLE-02.png?ex=65b14b13&is=659ed613&hm=28f375d139a8a103c1e555db21f58dca548e0acfdd402103dcf841a892271679&=&format=webp&quality=lossless&width=1440&height=322" alt="" className="man max-w-[350px] mt-[-0.5rem]" /> Testimonials
            </div>
            <a href="https://whop.com/unity-academy/">
                <div className="mb-12 more max-md:mb-0 shrink flex-wrap max-w-[650px] items-center m-auto justify-center shadow-sm flex gap-3 mt-6 px-5 py-1.5 rounded-[29px] bg-custom-grey">
                    <div className="text-white whitespace-nowrap text-sm font-bold self-center">
                        Our customers say Excellent
                    </div>
                    <img srcSet="images/5-stars.png" />
                    <div className="text-white whitespace-nowrap text-sm font-bold my-auto">
                        4.98 out of 5 based on reviews
                    </div>
                    <img className="w-6 h-6" srcSet="images/review-site-logo.png" />
                </div>
            </a>
            <Slider {...settings}>
                {Object.keys((props.set1 == "true" ? data.set1.row1 : data.set1.row1)).map((detailKey) => {
                    return (
                        <div className="newS mx-2 small1 max-w-[90vw]">
                            <div style={{ background: "linear-gradient(98deg, rgba(0, 0, 0, 0.31) 1.09%, rgba(104, 104, 104, 0.29) 100%)" }} className="h-full min-w-[300px] max-w-[500px] border justify-between shadow-lg flex grow flex-col pl-7 pr-5 py-7 rounded-3xl border-solid border-white border-opacity-20 max-md:mt-6 max-md:pl-5">
                                <div className="text-neutral-200 font-extrabold max-md:max-w-full">
                                    {props.set1 == "true" ? data.set1.row1[detailKey].head : data.set1.row1[detailKey].head}
                                </div>
                                <div className="text-neutral-400 text-[13.5px] mt-6 max-md:max-w-full max-md:mt-10">
                                    {props.set1 == "true" ? data.set1.row1[detailKey].body : data.set1.row1[detailKey].body}
                                </div>
                                <div className="flex w-full items-stretch justify-between gap-5 mt-4 max-md:max-w-full max-md:flex-wrap">
                                    <div className="flex items-stretch items-center justify-between gap-2.5">
                                        <img srcSet={props.set1 == "true" ? data.set1.row1[detailKey].pfp : data.set1.row1[detailKey].pfp} className="h-10 w-10 mt-1" />
                                        <div className="self-center ml-1 flex grow basis-[0%] flex-col items-stretch my-auto">
                                            <div className="text-neutral-200 font-semibold whitespace-nowrap">
                                                {props.set1 == "true" ? data.set1.row1[detailKey].name : data.set1.row1[detailKey].name}
                                            </div>
                                            <div className="text-neutral-200">
                                                Verified User
                                            </div>
                                        </div>
                                    </div>
                                    <img src="images/5-stars-orange.png" className="aspect-[4.63] object-contain object-center w-[111px] overflow-hidden self-center shrink-0 max-w-full my-auto" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>

        </div>

    );
}

export default OracleTrustedBy;